import { SupplierAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  suppliers: [],
  errors: [],
  isSuppliersLoaded: true,
};

const getters = {
  isSuppliersLoaded(state) {
    return state.isSuppliersLoaded;
  },
  getSuppliers(state) {
    return state.suppliers;
  },
  getSupplierErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsSuppliersLoaded: (state, payload) => {
    state.isSuppliersLoaded = payload;
  },
  setSuppliers: (state, payload) => {
    state.suppliers = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.suppliers.meta.current_page = payload.value;
  },

  setNewUpdateSupplier(state, payload) {
    let supplierIndex = state.suppliers.data.findIndex((o) => o.id == payload.id);
    if (supplierIndex !== -1) {
      // state.categories[categoryIndex] = payload;
      state.suppliers.data.splice(supplierIndex, 1, { ...payload });
    } else {
      state.suppliers.data.unshift(payload);
      state.suppliers.total_count += 1;
    }

    let SuppliersLength = state.suppliers.data.length;
    if (SuppliersLength > state.suppliers.meta.per_page) {
      state.suppliers.data.splice(SuppliersLength - 1, 1);
    }
  },
  deleteSupplier(state, payload) {
    let supplierIndex = state.suppliers.data.findIndex((o) => o.id === payload);
    if (supplierIndex !== -1) {
      state.suppliers.data.splice(supplierIndex, 1);
    }
  },
};

const actions = {
  fetchSuppliers: ({ commit }, data) => {
    commit("setIsSuppliersLoaded", false);
    return new Promise((resolve, reject) => {
      SupplierAPI.fetchSuppliers(data)
        .then((res) => {
          commit("setSuppliers", res.data);
          commit("setIsSuppliersLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsSuppliersLoaded", true);
          reject(error);
        });
    });
  },

  createSupplier: ({ commit }, data) => {
    commit("setIsSuppliersLoaded", false);
    return new Promise((resolve, reject) => {
      SupplierAPI.createSupplier(data)
        .then((res) => {
          commit("setErrors", "");
          if (!data.fastCreate) {
            commit("setNewUpdateSupplier", res.data.data);
          }
          commit("setIsSuppliersLoaded", true);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setIsSuppliersLoaded", true);
          let nameError = Object.values(error.response.data.errors);
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateSupplier: ({ commit }, data) => {
    commit("setIsSuppliersLoaded", false);
    return new Promise((resolve, reject) => {
      SupplierAPI.updateSupplier(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setNewUpdateSupplier", res.data.data);
          commit("setIsSuppliersLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSuppliersLoaded", true);
          let nameError = Object.values(error.response.data.errors);
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteSupplier: ({ commit }, data) => {
    commit("setIsSuppliersLoaded", false);
    return new Promise((resolve, reject) => {
      SupplierAPI.deleteSupplier(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsSuppliersLoaded", true);
          commit("deleteClient", id);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSuppliersLoaded", true);
          reject(error);
        });
    });
  },

  fetchSupplierBalance: ({ commit }, id) => {
    commit("setIsSuppliersLoaded", false);
    return new Promise((resolve, reject) => {
      SupplierAPI.fetchSupplierBalance(id)
        .then((res) => {
          commit("setIsSuppliersLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsSuppliersLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
