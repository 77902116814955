import { StoreAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  stores: [],
  errors: [],
  isStoresLoaded: false,

  store: {},
  userStores: [],
};

const getters = {
  isStoresLoaded(state) {
    return state.isStoresLoaded;
  },
  getStores(state) {
    return state.stores;
  },
  getStore(state) {
    return state.store;
  },
  getStoreErrors(state) {
    return state.errors;
  },

  getUserStore(state) {
    return state.userStores;
  },
};

const mutations = {
  setIsStoresLoaded: (state, payload) => {
    state.isStoresLoaded = payload;
  },
  setStores: (state, payload) => {
    state.stores = payload;
  },
  setStore: (state, payload) => {
    state.store = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.stores.meta.current_page = payload;
  },

  setUserStores(state, payload) {
    state.userStores = payload;
  },

  setNewUpdateStore(state, payload) {
    let storeIndex = state.stores.findIndex((o) => o.id === payload.id);
    if (storeIndex !== -1) {
      // state.stores[storeIndex] = payload;
      state.stores.splice(storeIndex, 1, { ...payload });
    } else {
      state.stores.unshift(payload);
    }
  },
  deleteStore(state, payload) {
    let storeIndex = state.stores.findIndex((o) => o.id === payload);
    // Check if drawer is found
    if (storeIndex !== -1) {
      state.stores.splice(storeIndex, 1); // Remove drawer
    }
  },
};

const actions = {
  fetchStore: ({ commit }) => {
    commit("setIsStoresLoaded", false);
    StoreAPI.fetchStore()
      .then((res) => {
        commit("setStore", res.data);
        commit("setIsStoresLoaded", true);
      })
      .catch((error) => {
        commit("setIsStoresLoaded", true);
      });
  },
  fetchStores: ({ commit }) => {
    commit("setIsStoresLoaded", false);
    StoreAPI.fetchStores()
      .then((res) => {
        commit("setStores", res.data.data);
        commit("setIsStoresLoaded", true);
      })
      .catch((error) => {
        commit("setIsStoresLoaded", true);
      });
  },

  createStore: ({ commit, dispatch }, data) => {
    commit("setIsStoresLoaded", false);
    StoreAPI.createStore(data)
      .then((res) => {
        commit("setErrors", "");
        commit("setIsStoresLoaded", true);

        commit("setNewUpdateStore", res.data.data);
        commit("User/setAssociate", { data: res.data.data, type: "stores" }, { root: true });
      })
      .catch((error) => {
        commit("setIsStoresLoaded", true);
        let nameError = Object.values(error.response.data.errors);
        commit("setErrors", nameError);
      });
  },

  updateStore: ({ commit, dispatch }, data) => {
    commit("setIsStoresLoaded", false);
    StoreAPI.updateStore(data)
      .then((res) => {
        commit("setErrors", "");
        commit("setIsStoresLoaded", true);

        commit("setNewUpdateStore", res.data.data);
        commit("User/setAssociate", { data: res.data.data, type: "stores" }, { root: true });
      })
      .catch((error) => {
        commit("setIsStoresLoaded", true);
        let nameError = Object.values(error.response.data.errors);
        commit("setErrors", nameError);
      });
  },
  deleteStore: ({ commit, dispatch }, data) => {
    commit("setIsStoresLoaded", false);
    return new Promise((resolve, reject) => {
      StoreAPI.deleteStore(data)
        .then((res) => {
          commit("setIsStoresLoaded", true);
          commit("setErrors", "");
          commit("deleteStore", data);
          commit("User/deleteAssociate", { data: data, type: "stores" }, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStoresLoaded", true);
          reject(error);
        });
    });
  },

  fetchUserStores: ({ commit }) => {
    commit("setIsStoresLoaded", false);
    return new Promise((resolve, reject) => {
      StoreAPI.fetchUserStores()
        .then((response) => {
          commit("setUserStores", response.data.data);
          commit("setIsStoresLoaded", true);
          resolve(response.data.data);
        })
        .catch((error) => {
          commit("setIsStoresLoaded", true);
          reject(error);
        });
    });
  },

  fetchBasicStores: ({ commit }) => {
    commit("setIsStoresLoaded", false);
    return new Promise((resolve, reject) => {
      StoreAPI.fetchBasicStores()
        .then((response) => {
          commit("setStores", response.data);
          commit("setIsStoresLoaded", true);
          resolve(response.data);
        })
        .catch((error) => {
          commit("setIsStoresLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
