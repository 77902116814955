import * as APIs from "@/helpers/Apis/CleanningInvoice/CleanningClients";
import i18n from "@/plugins/i18n/i18n";
const state = {
  cleanningclients: [],
  errors: [],
  isCleanningClientLoaded: true,

  currentPage: null,
};

const getters = {
  getCleanningClients(state) {
    return state.cleanningclients;
  },
  isCleanningClientLoaded(state) {
    return state.isCleanningClientLoaded;
  },
  getCleanningClientErrors(state) {
    return state.errors;
  },

  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setIsCleanningClientLoaded: (state, payload) => {
    state.isCleanningClientLoaded = payload;
  },
  setCleanningClients: (state, payload) => {
    state.cleanningclients = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  delClient: (state, payload) => {
    let data = state.cleanningclients.data;
    let index = data.findIndex((object) => {
      return object.id === payload;
    });
    data.splice(index, 1);
  },

  setCurrentPage(state, payload) {
    state.cleanningclients.meta.current_page = payload.value ? payload.value : payload;
  },
};

const actions = {
  fetchCleanningClients: ({ commit }, data) => {
    commit("setIsCleanningClientLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.fetchCleanningClients(data)
        .then((res) => {
          let resultData = res.data;
          commit("setCleanningClients", resultData);
          commit("setIsCleanningClientLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCleanningClientLoaded", true);
          reject(error);
        });
    });
  },

  createCleanningClient: ({ commit, dispatch }, data) => {
    commit("setIsCleanningClientLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.createCleanningClient(data)
        .then((res) => {
          commit("setIsCleanningClientLoaded", true);
          commit("setErrors", "");
          dispatch("fetchCleanningClients", { pageNumber: 0 });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCleanningClientLoaded", true);
          reject(error.response);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updateCleanningClient: ({ state, commit, dispatch }, data) => {
    commit("setIsCleanningClientLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.updateCleanningClient(data)
        .then((response) => {
          commit("setIsCleanningClientLoaded", true);
          commit("setErrors", "");
          dispatch("fetchCleanningClients", {
            pageNumber: state.cleanningclients.meta.current_page,
          });
          resolve(response);
        })
        .catch((error) => {
          commit("setIsCleanningClientLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  deleteCleanningClient: ({ commit }, id) => {
    commit("setIsCleanningClientLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.deleteCleanningClient(id)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsCleanningClientLoaded", true);
          commit("delClient", id);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCleanningClientLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
