import { ClientAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  clients: [],
  errors: [],
  isClientsLoaded: true,
};

const getters = {
  isClientsLoaded(state) {
    return state.isClientsLoaded;
  },
  getClients(state) {
    return state.clients;
  },

  getClientErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsClientsLoaded: (state, payload) => {
    state.isClientsLoaded = payload;
  },
  setClients: (state, payload) => {
    state.clients = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.clients.meta.current_page = payload.value;
  },

  setNewUpdateClient(state, payload) {
    let clientIndex = state.clients.data.findIndex((o) => o.id == payload.id);
    if (clientIndex !== -1) {
      // state.categories[categoryIndex] = payload;
      state.clients.data.splice(clientIndex, 1, { ...payload });
    } else {
      state.clients.data.unshift(payload);
      state.clients.total_count += 1;
    }

    let ClientsLength = state.clients.data.length;
    if (ClientsLength > state.clients.meta.per_page) {
      state.clients.data.splice(ClientsLength - 1, 1);
    }
  },
  deleteClient(state, payload) {
    let clientIndex = state.clients.data.findIndex((o) => o.id === payload);
    if (clientIndex !== -1) {
      state.clients.data.splice(clientIndex, 1);
    }
  },
};

const actions = {
  fetchClients: ({ commit }, data) => {
    commit("setIsClientsLoaded", false);
    return new Promise((resolve, reject) => {
      ClientAPI.fetchClients(data)
        .then((res) => {
          commit("setClients", res.data);
          commit("setIsClientsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsClientsLoaded", true);
          reject(error);
        });
    });
  },

  createClient: ({ commit }, data) => {
    commit("setIsClientsLoaded", false);
    return new Promise((resolve, reject) => {
      ClientAPI.createClient(data)
        .then((res) => {
          commit("setErrors", "");
          if (!data.fastCreate) {
            commit("setNewUpdateClient", res.data.data);
          }
          resolve(res.data.data);
          commit("setIsClientsLoaded", true);
        })
        .catch((error) => {
          commit("setIsClientsLoaded", true);
          let nameError = Object.values(error.response.data.errors);
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateClient: ({ commit }, data) => {
    commit("setIsClientsLoaded", false);
    return new Promise((resolve, reject) => {
      ClientAPI.updateClient(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setNewUpdateClient", res.data.data);
          commit("setIsClientsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsClientsLoaded", true);
          let nameError = Object.values(error.response.data.errors);
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteClient: ({ commit }, id) => {
    commit("setIsClientsLoaded", false);
    return new Promise((resolve, reject) => {
      ClientAPI.deleteClient(id)
        .then((res) => {
          commit("setIsClientsLoaded", true);
          commit("setErrors", "");
          commit("deleteClient", id);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsClientsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);

          reject(error);
        });
    });
  },
  getClientBalance: ({ commit }, id) => {
    commit("setIsClientsLoaded", false);
    return new Promise((resolve, reject) => {
      ClientAPI.getClientBalance(id)
        .then((res) => {
          commit("setIsClientsLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsClientsLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
