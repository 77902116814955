var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-badge',{staticClass:"badge",attrs:{"content":_vm.getNotifications.length,"value":_vm.getNotifications.length,"color":"red darken-2","overlap":true}},[_c('v-menu',{attrs:{"bottom":"","min-width":"320px","max-width":"320px","close-on-content-click":false,"rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menuOn = ref.on;
return [_c('v-tooltip',{attrs:{"top":"","color":"toolTipBgColor"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),Object.assign({}, menuOn,
              tooltipOn)),[_vm._v(" mdi-bell ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("notifications")))])])]}}])},[_c('v-card',{staticClass:"text-end"},[_c('div',[(_vm.getNotifications.length > 0)?_c('v-tooltip',{attrs:{"top":"","color":"toolTipBgColor"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pa-1",attrs:{"icon":"","color":"red darken-2 "},on:{"click":function($event){return _vm.deleteNotifications()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-alert ")])]}}],null,false,3043593084)},[_c('span',[_vm._v(_vm._s(_vm.$t("deleteall")))])]):_vm._e(),_c('v-tooltip',{attrs:{"top":"","color":"toolTipBgColor"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pa-1",attrs:{"icon":"","color":"green darken-2 "},on:{"click":function($event){return _vm.fetchNotifications()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-reload ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("reload")))])])],1),_c('v-card-text',{staticClass:"cardStyle pt-0"},[(_vm.isNotificationsLoaded)?_c('v-list-item-content',{staticClass:"listStyle"},[(_vm.getNotifications.length == 0)?_c('div',[_vm._v(_vm._s(_vm.$t("nodata")))]):_vm._l((_vm.getNotifications),function(notification){return _c('div',{key:notification.id,staticClass:"text-center"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('h3',{staticClass:"bodyText"},[_vm._v(_vm._s(notification.message))]),_c('v-divider',{staticClass:"mx-1",attrs:{"vertical":""}}),_c('h4',{staticClass:"dateText"},[_vm._v(_vm._s(notification.created_at))]),_c('div',{staticClass:"d-flex"},[_c('v-divider',{staticClass:"mx-1",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"top":"","color":"toolTipBgColor"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteNotification(notification.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-alpha-x-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("delete")))])])],1)],1),_c('v-divider',{staticClass:"mt-2"})],1)})],2):_c('div',{staticClass:"h-100 d-flex align-center justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }