import * as APIs from "@/helpers/Apis/Company";
import { UserAPI } from "@/helpers/Apis/User/Users";
import i18n from "@/plugins/i18n/i18n";

const state = {
  companyData: JSON.parse(localStorage.getItem("company")) || null,
  isCompanyLoaded: true,

  database: localStorage.getItem("db") || null,

  loginUsers: [],
  isUsersLoaded: true,

  errors: [],
};

const getters = {
  getCompanyData(state) {
    return state.companyData;
  },
  getDatabase(state) {
    return state.database;
  },
  isCompanyLoaded(state) {
    return state.isCompanyLoaded;
  },

  getLoginUsers(state) {
    return state.loginUsers;
  },
  isUsersLoaded(state) {
    return state.isUsersLoaded;
  },

  getErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsCompanyLoaded: (state, payload) => {
    state.isCompanyLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setLoginUsers: (state, payload) => {
    state.loginUsers = payload;
  },
  setIsUsersLoaded: (state, payload) => {
    state.isUsersLoaded = payload;
  },

  setDatabase: (state, payload) => {
    state.database = payload;
    localStorage.setItem("db", payload);
  },
  setCompanyData: (state, payload) => {
    state.companyData = payload;
    localStorage.setItem(
      "company",
      JSON.stringify({
        email: payload.email,
        name: payload.name,
        display_name: payload.display_name,
        tax_registeration: payload.tax_registeration,
        renewal_date: payload.renewal_date,
        logo: payload.logo,
        permissions: payload.permissions,
      })
    );
  },

  setCompanyEmail: (state, payload) => {
    let companyData = JSON.parse(localStorage.getItem("company"));
    companyData.email = payload;

    state.companyData = companyData;
    localStorage.setItem("company", JSON.stringify(companyData));
  },
};

const actions = {
  companyLogin: ({ commit }, data) => {
    commit("setIsCompanyLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.companyLogin(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsCompanyLoaded", true);
          // commit("setCompanyData", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCompanyLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchDatabases: ({ commit }, data) => {
    commit("setIsCompanyLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.fetchDatabases(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsCompanyLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCompanyLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  loginUsers: ({ commit }, data) => {
    commit("setIsUsersLoaded", false);
    return new Promise((resolve, reject) => {
      UserAPI.loginUsers(data)
        .then((res) => {
          commit("setLoginUsers", res.data.data);
          commit("setIsUsersLoaded", true);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUsersLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  editEmail: ({ commit }, data) => {
    commit("setIsCompanyLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.editEmail(data)
        .then((res) => {
          commit("setCompanyEmail", data.email);
          commit("setIsCompanyLoaded", true);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCompanyLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  editPassword: ({ commit }, data) => {
    commit("setIsCompanyLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.editPassword(data)
        .then((res) => {
          commit("setIsCompanyLoaded", true);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCompanyLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
