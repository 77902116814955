import baseUrl from "@/constants/API";

export const ItemPackageAPI = {
  fetchPackages() {
    return axios.get(baseUrl() + `/itempackages/get`);
  },

  fetchPackageItems(id) {
    return axios.get(baseUrl() + `/itempackages/${id}/items`);
  },

  createPackage(data) {
    return axios.post(baseUrl() + `/itempackages/create`, data);
  },

  updatePackage(data) {
    return axios.post(baseUrl() + `/itempackages/update/${data.id}`, data.data);
  },

  deletePackage(id) {
    return axios.delete(baseUrl() + `/itempackages/delete/${id}`);
  },
};
