import baseUrl from "@/constants/API";

const fetchBeginningMainAccs = (branchId) => {
  return axios.get(baseUrl() + `/openbalance/mainaccs/${branchId}`);
};

const fetchMainAccsEntries = (id) => {
  return axios.get(baseUrl() + `/openbalance/mainaccentries/${id}`);
};

const fetchBalanceEntries = () => {
  return axios.get(baseUrl() + "/openbalance/all");
};
const fetchMainAccEntries = (data) => {
  return axios.post(baseUrl() + `/openbalance/mainaccounts?page=${data.pageNumber}`, data.data);
};

const createEntryDetail = (data) => {
  return axios.post(baseUrl() + `/openbalance/create`, data);
};

const updateEntryDetail = (data) => {
  return axios.post(baseUrl() + `/openbalance/update/${data.id}`, data.data);
};

const createUpdateManyDetails = (data) => {
  return axios.post(baseUrl() + `/openbalance/createupdatemany`, data);
};

const deleteEntryDetail = (data) => {
  return axios.delete(baseUrl() + `/openbalance/delete/${data}`);
};

export {
  fetchMainAccsEntries,
  fetchBeginningMainAccs,
  fetchBalanceEntries,
  fetchMainAccEntries,
  createEntryDetail,
  updateEntryDetail,
  createUpdateManyDetails,
  deleteEntryDetail,
};
