import baseUrl from "@/constants/API";

export const TransferAPI = {
  fetchStoreTransfer(id) {
    return axios.get(baseUrl() + `/storetransfer/${id}`);
  },
  storeTransferSearch(data) {
    return axios.post(baseUrl() + `/storetransfer/search?page=${data.pageNumber}`, data.data);
  },

  fetchStoreTransfers(data) {
    // return axios.post(baseUrl() + `/storetransfer/all?page=${pageNumber}`);
    let page = data?.page ? data.page : data;
    let invoice_id = data?.invoice_id ? data.invoice_id : null;
    return axios.get(baseUrl() + `/storetransfer/invoices/${invoice_id}?page=${page}`);
  },
  createStoreTransfer(data) {
    return axios.post(baseUrl() + `/storetransfer/create`, data);
  },
  updateStoreTransfer(data) {
    return axios.put(baseUrl() + `/storetransfer/update/${data.id}`, data.data);
  },
  deleteStoreTransfer(data) {
    return axios.delete(baseUrl() + `/storetransfer/delete/${data.id}`);
  },

  transferDetails(invoice_id) {
    return axios.post(baseUrl() + `/storetransfer/details/${invoice_id}`);
  },

  storeTransferDeliver(id) {
    return axios.put(baseUrl() + `/storetransfer/deliver/${id}`);
  },
  storeTransferUnDeliver(id) {
    return axios.put(baseUrl() + `/storetransfer/undeliver/${id}`);
  },

  // deleted STUFF
  fetchDeletedInvoices(data) {
    return axios.post(baseUrl() + `/storetransfer/deleted?page=${data.pageNumber}`, data.data);
  },
  fetchDeletedInvoiceDetails(invoice_id) {
    return axios.post(baseUrl() + `/storetransfer/deleted/${invoice_id}`);
  },
  clearDeletedInvoices(data) {
    return axios.delete(baseUrl() + `/storetransfer/deleted/clear`, data);
  },
};
