import baseUrl from "@/constants/API";

export const OrderAPI = {
  /// --------------- SALE ORDERS ---------------
  fetchSaleOrder(id) {
    return axios.get(baseUrl() + `/saleorder/${id}`);
  },
  createSaleOrder(data) {
    return axios.post(baseUrl() + `/saleorder/create`, data);
  },
  fetchSaleOrders(data) {
    let page = data?.page ? data.page : data;
    let invoice_id = data?.invoice_id ? data.invoice_id : null;
    return axios.get(baseUrl() + `/saleorder/invoices/${invoice_id}?page=${page}`);
  },
  updateSaleOrder(data) {
    return axios.post(baseUrl() + `/saleorder/update/${data.id}`, data.data);
  },
  deleteSaleOrder(id) {
    return axios.delete(baseUrl() + `/saleorder/delete/${id}`);
  },

  SaleOrderDetails(invoice_id) {
    return axios.post(baseUrl() + `/saleorder/details/${invoice_id}`);
  },

  orderConvertToSale(data) {
    return axios.post(baseUrl() + `/saleorder/converttosale/${data.SaleOrderId}`, data.data);
  },

  // deleted STUFF
  getDeletedSaleOrders(data) {
    return axios.post(baseUrl() + `/saleorder/deleted?page=${data.pageNumber}`, data.data);
  },
  getDeletedSaleOrderDetails(invoice_id) {
    return axios.post(baseUrl() + `/saleorder/deleted/${invoice_id}`);
  },
  clearDeletedSaleOrders(data) {
    return axios.delete(baseUrl() + `/saleorder/deleted/clear`, data);
  },

  /// --------------- Purchase ORDERS ---------------
  fetchPurchaseOrder(id) {
    return axios.get(baseUrl() + `/purchaseorder/${id}`);
  },
  createPurchaseOrder(data) {
    return axios.post(baseUrl() + `/purchaseorder/create`, data);
  },
  fetchPurchaseOrders(data) {
    let page = data?.page ? data.page : data;
    let invoice_id = data?.invoice_id ? data.invoice_id : null;
    return axios.get(baseUrl() + `/purchaseorder/invoices/${invoice_id}?page=${page}`);
  },
  updatePurchaseOrder(data) {
    return axios.post(baseUrl() + `/purchaseorder/update/${data.id}`, data.data);
  },
  deletePurchaseOrder(id) {
    return axios.delete(baseUrl() + `/purchaseorder/delete/${id}`);
  },

  PurchaseOrderDetails(invoice_id) {
    return axios.post(baseUrl() + `/purchaseorder/details/${invoice_id}`);
  },

  orderConvertToPurchase(data) {
    return axios.post(
      baseUrl() + `/purchaseorder/converttopurchase/${data.PurchaseOrderId}`,
      data.data
    );
  },

  // deleted STUFF
  getDeletedPurchaseOrders(data) {
    return axios.post(baseUrl() + `/purchaseorder/deleted?page=${data.pageNumber}`, data.data);
  },
  getDeletedPurchaseOrderDetails(invoice_id) {
    return axios.post(baseUrl() + `/purchaseorder/deleted/${invoice_id}`);
  },
  clearDeletedPurchaseOrders(data) {
    return axios.delete(baseUrl() + `/purchaseorder/deleted/clear`, data);
  },
};
