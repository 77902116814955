import * as APIs from "@/helpers/Apis/Beginning/BeginningHead";
import i18n from "@/plugins/i18n/i18n";

const state = {
  openinghead: [],
  isBeginningLoaded: true,
  errors: [],
};

const getters = {
  getBeginningHead(state) {
    return state.openinghead;
  },
  isBeginningLoaded(state) {
    return state.isBeginningLoaded;
  },
  getBeginningHeadErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsBeginningLoaded: (state, payload) => {
    state.isBeginningLoaded = payload;
  },
  setBeginningHead: (state, payload) => {
    state.openinghead = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  fetchBeginningHead: ({ commit }, storeId) => {
    commit("setIsBeginningLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.fetchBeginningHead(storeId)
        .then((res) => {
          commit("setBeginningHead", res.data);
          commit("setIsBeginningLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsBeginningLoaded", true);
          reject(error);
        });
    });
  },

  updateBeginningHead: ({ commit }, data) => {
    commit("setIsBeginningLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.updateBeginningHead(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsBeginningLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsBeginningLoaded", true);

          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
