import baseUrl from "@/constants/API";

const fetchBeginningHead = () => {
  return axios.get(baseUrl() + "/openinghead/get");
};

const updateBeginningHead = (data) => {
  return axios.post(baseUrl() + "/openinghead/update", data);
};

export { fetchBeginningHead, updateBeginningHead };
