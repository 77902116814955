import * as APIs from "@/helpers/Apis/Classifications/ItemClassifications";
import i18n from "@/plugins/i18n/i18n";

const state = {
  classifications: [],
  errors: [],
  isClassificationsLoaded: true,
};

const getters = {
  isClassificationsLoaded(state) {
    return state.isClassificationsLoaded;
  },
  getClassifications(state) {
    return state.classifications;
  },
  getClassificationErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsClassificationsLoaded: (state, payload) => {
    state.isClassificationsLoaded = payload;
  },
  setClassifications: (state, payload) => {
    state.classifications = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setNewUpdateClassification(state, payload) {
    let ClassificationId = payload.id;
    let Classification = payload.data;
    if (ClassificationId) {
      let ClassificationIndex = state.classifications.findIndex((o) => o.id === Classification.id);
      if (ClassificationIndex !== -1) {
        // state.classifications[ClassificationIndex] = Classification;
        state.classifications.splice(ClassificationIndex, 1, { ...Classification });
      }
    } else {
      state.classifications.unshift(Classification);
    }
  },
  deleteClassification: (state, payload) => {
    let ClassificationIndex = state.classifications.findIndex((o) => o.id === payload);
    if (ClassificationIndex !== -1) {
      state.classifications.splice(ClassificationIndex, 1); // Remove drawer
    }
  },
};

const actions = {
  fetchClassifications: ({ commit }) => {
    commit("setIsClassificationsLoaded", false);
    APIs.fetchClassifications()
      .then((res) => {
        commit("setClassifications", res.data);
        commit("setIsClassificationsLoaded", true);
      })
      .catch((error) => {
        commit("setIsClassificationsLoaded", true);
      });
  },

  createClassification: ({ commit }, data) => {
    commit("setIsClassificationsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.createClassification(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setNewUpdateClassification", { id: null, data: res.data.data });
          commit("setIsClassificationsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsClassificationsLoaded", true);
          let nameError = Object.values(error.response.data.errors);
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateClassification: ({ commit }, data) => {
    commit("setIsClassificationsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.updateClassification(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setNewUpdateClassification", { id: data.id, data: res.data.data });
          commit("setIsClassificationsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsClassificationsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteClassification: ({ commit }, data) => {
    commit("setIsClassificationsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.deleteClassification(data)
        .then((res) => {
          commit("setErrors", "");
          commit("deleteClassification", id);
          commit("setIsClassificationsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsClassificationsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);

          reject(error);
        });
    });
  },

  fetchSearchClassifications: ({ commit }, data) => {
    commit("setIsClassificationsLoaded", false);
    APIs.fetchSearchClassifications(data)
      .then((res) => {
        commit("setErrors", "");
        commit("setClassifications", res.data);
        commit("setIsClassificationsLoaded", true);
      })
      .catch((error) => {
        commit("setIsClassificationsLoaded", true);
        let nameError = error.response.data.errors
          ? Object.values(error.response.data.errors)
          : [error.response.data.message];
        commit("setErrors", nameError);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
