import { UserControlAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  basicUsers: [],

  users: [],
  roles: [],
  isUserControlLoaded: true,
  errors: [],
  userAssociates: [],
};

const getters = {
  getBasicUsers(state) {
    return state.basicUsers;
  },

  getUsers(state) {
    return state.users;
  },
  getRoles(state) {
    return state.roles;
  },
  isUserControlLoaded(state) {
    return state.isUserControlLoaded;
  },

  getErrors(state) {
    return state.errors;
  },
  getAssociates(state) {
    return state.userAssociates;
  },
};

const mutations = {
  setBasicUsers: (state, payload) => {
    state.basicUsers = payload;
  },

  setUsers: (state, payload) => {
    state.users = payload;
  },
  setRoles: (state, payload) => {
    state.roles = payload;
  },

  setIsUserControlLoaded: (state, payload) => {
    state.isUserControlLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setUserAssociates: (state, payload) => {
    state.userAssociates = payload;
  },

  setUserPermissions: (state, payload) => {
    let user = state.users.find((o) => o.id === payload.user_id);
    user.permissions = payload.permission;
  },

  setNewUpdateUser(state, payload) {
    let userIndex = state.users.findIndex((o) => o.id === payload.id);
    if (userIndex !== -1) {
      state.users.splice(userIndex, 1, { ...payload });
    } else {
      state.users.unshift(payload);
    }
  },
  deleteUser(state, payload) {
    let userIndex = state.users.findIndex((o) => o.id === payload);
    // Check if drawer is found
    if (userIndex !== -1) {
      state.users.splice(userIndex, 1); // Remove drawer
    }
  },
};

const actions = {
  downloadDB: ({ commit }) => {
    commit("setIsUserControlLoaded", false);
    return UserControlAPI.downloadDB()
      .then((res) => {
        commit("setIsUserControlLoaded", true);
        // Create a Blob and generate a URL for download
        const blob = new Blob([res.data], { type: res.headers["content-type"] });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        // Extract filename from Content-Disposition header, default to 'download.sql'
        const contentDisposition = res.headers["content-disposition"];
        const fileName = contentDisposition
          ? contentDisposition.split("filename=")[1].trim().replace(/['"]/g, "")
          : "download.sql";

        // Trigger the file download
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        return res;
      })
      .catch((error) => {
        commit("setIsUserControlLoaded", true);
        const nameError = error.response.data.errors
          ? Object.values(error.response.data.errors)
          : [error.response.data.message];
        commit("setErrors", nameError);
        return Promise.reject(error);
      });
  },

  fetchBasicUsers: ({ state, commit }, data) => {
    if (state.basicUsers.length <= 0) {
      commit("setIsUserControlLoaded", false);
      return new Promise((resolve, reject) => {
        UserControlAPI.fetchBasicUsers(data)
          .then((res) => {
            commit("setBasicUsers", res.data);
            commit("setIsUserControlLoaded", true);
            resolve(res);
          })
          .catch((error) => {
            commit("setIsUserControlLoaded", true);
            let nameError = error.response.data.errors
              ? Object.values(error.response.data.errors)
              : [error.response.data.message];
            commit("setErrors", nameError);
            reject(error);
          });
      });
    }
  },

  fetchUsers: ({ commit }, data) => {
    commit("setIsUserControlLoaded", false);
    return new Promise((resolve, reject) => {
      UserControlAPI.fetchUsers(data)
        .then((res) => {
          commit("setUsers", res.data.data);
          commit("setIsUserControlLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUserControlLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  registerUser: ({ commit }, data) => {
    commit("setIsUserControlLoaded", false);
    return new Promise((resolve, reject) => {
      UserControlAPI.registerUser(data)
        .then((res) => {
          commit("setIsUserControlLoaded", true);
          commit("setNewUpdateUser", res.data.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsUserControlLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  deleteUser: ({ commit }, id) => {
    commit("setIsUserControlLoaded", false);
    return new Promise((resolve, reject) => {
      UserControlAPI.deleteUser(id)
        .then((res) => {
          resolve(res);
          commit("setIsUserControlLoaded", true);
          commit("deleteUser", id);
        })
        .catch((error) => {
          commit("setIsUserControlLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  addRemovePermission: ({ commit }, data) => {
    commit("setIsUserControlLoaded", false);
    return new Promise((resolve, reject) => {
      UserControlAPI.addRemovePermission(data)
        .then((res) => {
          commit("setIsUserControlLoaded", true);
          commit("setUserPermissions", data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUserControlLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  defaultUserPermissions: ({ commit }, id) => {
    commit("setIsUserControlLoaded", false);
    return new Promise((resolve, reject) => {
      UserControlAPI.defaultUserPermissions(id)
        .then((res) => {
          commit("setIsUserControlLoaded", true);
          commit("setNewUpdateUser", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUserControlLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  saveUserDefaults: ({ commit }, data) => {
    commit("setIsUserControlLoaded", false);
    return new Promise((resolve, reject) => {
      UserControlAPI.saveUserDefaults(data)
        .then((res) => {
          commit("setIsUserControlLoaded", true);
          commit("setErrors", "");
          commit("setNewUpdateUser", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUserControlLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateUserPassword: ({ commit }, data) => {
    commit("setIsUserControlLoaded", false);
    return new Promise((resolve, reject) => {
      UserControlAPI.updateUserPassword(data)
        .then((res) => {
          commit("setIsUserControlLoaded", true);
          commit("setNewUpdateUser", res.data.data);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          // commit logout
          commit("setIsUserControlLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  updateUserName: ({ commit }, data) => {
    commit("setIsUserControlLoaded", false);
    return new Promise((resolve, reject) => {
      UserControlAPI.updateUserName(data)
        .then((res) => {
          commit("setIsUserControlLoaded", true);
          commit("setErrors", "");
          commit("setNewUpdateUser", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUserControlLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  logOutUser: ({ commit }, id) => {
    commit("setIsUserControlLoaded", false);
    return new Promise((resolve, reject) => {
      UserControlAPI.logOutUser(id)
        .then((res) => {
          commit("setIsUserControlLoaded", true);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUserControlLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchRoles: ({ commit }) => {
    commit("setIsUserControlLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      UserControlAPI.fetchRoles()
        .then((res) => {
          commit("setIsUserControlLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setRoles", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUserControlLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);
        });
    });
  },
  fetchUserRoles: ({ commit }) => {
    commit("setIsUserControlLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      UserControlAPI.fetchUserRoles()
        .then((res) => {
          commit("setIsUserControlLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUserControlLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);
        });
    });
  },

  updateRole: ({ commit, dispatch }, data) => {
    commit("setIsUserControlLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      UserControlAPI.updateRole(data)
        .then((res) => {
          commit("setIsUserControlLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          dispatch("fetchRoles");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUserControlLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);
        });
    });
  },

  fetchUserAssociates: ({ commit }, data) => {
    commit("setIsUserControlLoaded", false);
    return new Promise((resolve, reject) => {
      UserControlAPI.fetchUserAssociates(data)
        .then((res) => {
          commit("setIsUserControlLoaded", true);
          commit("setUserAssociates", res.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUserControlLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
