import baseUrl from "@/constants/API";

const getFreeProduction = (id) => {
  return axios.get(baseUrl() + `/production/free/${id}`);
};
const getFreeProductions = (data) => {
  // return axios.get(baseUrl() + `/production/all?page=${pageNumber}`);
  let page = data?.page ? data.page : data;
  let invoice_id = data?.invoice_id ? data.invoice_id : null;
  return axios.get(baseUrl() + `/production/free/${invoice_id}?page=${page}`);
};

const createFreeProduction = (data) => {
  return axios.post(baseUrl() + `/production/free/create`, data);
};
const updateFreeProduction = (data) => {
  return axios.post(baseUrl() + `/production/free/update/${data.id}`, data.data);
};

const deleteProduction = (id) => {
  return axios.delete(baseUrl() + `/production/delete/${id}`);
};

export {
  getFreeProduction,
  getFreeProductions,
  createFreeProduction,
  updateFreeProduction,
  deleteProduction,
};
