import { SaleReportAPI } from "@/helpers/Apis/Invoices/Sales/Reports/Sale";
import i18n from "@/plugins/i18n/i18n";

const state = {
  detailData: [],
  invoiceData: [],
  errors: [],
  isReportDataLoaded: true,
  currentPage: null,
};

const getters = {
  getDetailData(state) {
    return state.detailData;
  },
  getReportData(state) {
    return state.invoiceData;
  },
  isReportDataLoaded(state) {
    return state.isReportDataLoaded;
  },
  getErrors(state) {
    return state.errors;
  },
  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setDetailData: (state, payload) => {
    state.detailData = payload;
  },
  setInvoiceData: (state, payload) => {
    state.invoiceData = payload;
  },
  setIsReportDataLoaded: (state, payload) => {
    state.isReportDataLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.data.meta.current_page = payload;
  },
  setCurrentPage(state, payload) {
    let collection = payload.collection;
    state[`${collection}`].meta.current_page = payload.value;
  },
};

const actions = {
  fetchSaleDetailSearch: ({ commit }, data) => {
    commit("setIsReportDataLoaded", false);
    return new Promise((resolve, reject) => {
      SaleReportAPI.fetchSaleDetailSearch(data)
        .then((res) => {
          commit("setDetailData", res.data);
          commit("setErrors", []);
          commit("setIsReportDataLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsReportDataLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchSaleSearch: ({ commit }, data) => {
    commit("setIsReportDataLoaded", false);
    return new Promise((resolve, reject) => {
      SaleReportAPI.fetchSaleSearch(data)
        .then((res) => {
          commit("setInvoiceData", res.data);
          commit("setErrors", []);
          commit("setIsReportDataLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsReportDataLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
