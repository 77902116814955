import * as APIs from "@/helpers/Apis/Item/Expires";
import i18n from "@/plugins/i18n/i18n";

const state = {
  expireQtyFiltered: [],
  isDataLoaded: true,
  errors: [],

  currentPage: null,
};

const getters = {
  getExpireQtyFiltered(state) {
    return state.expireQtyFiltered;
  },
  getIsReportLoaded(state) {
    return state.isDataLoaded;
  },
  getErrors(state) {
    return state.errors;
  },

  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setExpireQtyFiltered: (state, payload) => {
    state.expireQtyFiltered = payload;
  },
  setIsExpireLoaded(state, payload) {
    state.isDataLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setCurrentPage(state, payload) {
    state.expireQtyFiltered.meta.current_page = payload.value;
  },
};

const actions = {
  fetchExpiresQtyFiltered: ({ commit }, data) => {
    commit("setIsExpireLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.fetchExpiresQtyFiltered(data)
        .then((res) => {
          commit("setIsExpireLoaded", true);
          commit("setExpireQtyFiltered", res.data);
          commit("setErrors", []);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setIsExpireLoaded", true);
          let nameError = Object.values(error.response.data.errors);
          commit("setErrors", nameError[0]);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
