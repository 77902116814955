import { render, staticRenderFns } from "./Notifications.vue?vue&type=template&id=d8adbd26&scoped=true"
import script from "./Notifications.vue?vue&type=script&lang=js"
export * from "./Notifications.vue?vue&type=script&lang=js"
import style0 from "./Notifications.vue?vue&type=style&index=0&id=d8adbd26&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8adbd26",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VCard,VCardText,VDivider,VIcon,VListItemContent,VMenu,VProgressCircular,VTooltip})
