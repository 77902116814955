import baseUrl from "@/constants/API";

export const RestaurantMealAPI = {
  fetchMeals() {
    return axios.get(baseUrl() + `/restaurant/meal/get`);
  },

  fetchCachedMeals() {
    return axios.get(baseUrl() + `/restaurant/meal/getcache`);
  },

  fetchMealProportions(id) {
    return axios.get(baseUrl() + `/restaurant/meal/getproportions/${id}`);
  },

  createMeal(data) {
    return axios.post(baseUrl() + `/restaurant/meal/create`, data);
  },
  updateMeal(data) {
    return axios.post(baseUrl() + `/restaurant/meal/update/${data.id}`, data.data);
  },

  deleteMeal(id) {
    return axios.delete(baseUrl() + `/restaurant/meal/delete/${id}`);
  },
  clearCache() {
    return axios.post(baseUrl() + `/restaurant/meal/clearcache`);
  },
};
