import * as APIs from "@/helpers/Apis/DefaultAcc";
import i18n from "@/plugins/i18n/i18n";

const state = {
  defaultaccs: [],
  errors: [],
  isDefaultAccsLoaded: false,
};

const getters = {
  isDefaultAccsLoaded(state) {
    return state.isDefaultAccsLoaded;
  },
  getDefaultAccs(state) {
    return state.defaultaccs;
  },
  getDefaultAccErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsDefaultAccsLoaded: (state, payload) => {
    state.isDefaultAccsLoaded = payload;
  },
  setDefaultAccs: (state, payload) => {
    state.defaultaccs = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  fetchDefaultAccs: ({ commit }) => {
    commit("setIsDefaultAccsLoaded", false);
    APIs.fetchDefaultAccs()
      .then((response) => {
        commit("setDefaultAccs", response.data);
        commit("setIsDefaultAccsLoaded", true);
      })
      .catch((error) => {
        commit("setIsDefaultAccsLoaded", true);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
