import * as APIs from "@/helpers/Apis/Notifications/Notifications";

const state = {
  notifications: [],
  isNotificationsLoaded: true,
};

const getters = {
  getNotifications(state) {
    return state.notifications;
  },
  isNotificationsLoaded(state) {
    return state.isNotificationsLoaded;
  },
};

const mutations = {
  setNotifications: (state, payload) => {
    state.notifications = payload;
  },
  setIsNotificationsLoaded: (state, payload) => {
    state.isNotificationsLoaded = payload;
  },

  deleteNotification(state, payload) {
    let notificationIndex = state.notifications.findIndex((o) => o.id === payload);
    if (notificationIndex !== -1) {
      state.notifications.splice(notificationIndex, 1);
    }
  },
};

const actions = {
  fetchNotifications: ({ commit }) => {
    commit("setIsNotificationsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.fetchNotifications()
        .then((res) => {
          commit("setIsNotificationsLoaded", true);
          commit("setNotifications", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsNotificationsLoaded", true);
          reject(error);
        });
    });
  },
  deleteNotification: ({ commit }, id) => {
    commit("setIsNotificationsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.deleteNotification(id)
        .then((res) => {
          commit("setIsNotificationsLoaded", true);
          commit("deleteNotification", id);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsNotificationsLoaded", true);
          reject(error);
        });
    });
  },
  deleteNotifications: ({ commit }) => {
    commit("setIsNotificationsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.deleteNotifications()
        .then((res) => {
          commit("setIsNotificationsLoaded", true);
          commit("setNotifications", []);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsNotificationsLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
