import * as APIs from "@/helpers/Apis/Item/Serials";
import i18n from "@/plugins/i18n/i18n";

const state = {
  itemSerials: [],
  isDataLoaded: true,
  errors: [],
};

const getters = {
  getItemSerials(state) {
    return state.itemSerials;
  },
  getIsSerialsLoaded(state) {
    return state.isDataLoaded;
  },
  getErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setItemSerials: (state, payload) => {
    state.itemSerials = payload;
  },
  setIsSerialsLoaded(state, payload) {
    state.isDataLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.items.meta.current_page = payload;
  },
};

const actions = {
  fetchPurchaseReturnSerials: ({ commit }, data) => {
    commit("setIsSerialsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.fetchPurchaseReturnSerials(data)
        .then((res) => {
          commit("setIsSerialsLoaded", true);
          commit("setItemSerials", res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsSerialsLoaded", true);
          reject(error);
        });
    });
  },

  fetchSaleSerials: ({ commit }, data) => {
    commit("setIsSerialsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.fetchSaleSerials(data)
        .then((res) => {
          commit("setIsSerialsLoaded", true);
          commit("setItemSerials", res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsSerialsLoaded", true);
          reject(error);
        });
    });
  },
  fetchSaleReturnSerials: ({ commit }, data) => {
    commit("setIsSerialsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.fetchSaleReturnSerials(data)
        .then((res) => {
          commit("setIsSerialsLoaded", true);
          commit("setItemSerials", res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsSerialsLoaded", true);
          reject(error);
        });
    });
  },

  fetchItemSerialsChecker: ({ commit }, data) => {
    commit("setIsSerialsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.fetchItemSerialsChecker(data)
        .then((response) => {
          commit("setIsSerialsLoaded", true);
          resolve(response.data.data);
        })
        .catch((error) => {
          commit("setIsSerialsLoaded", true);
          reject(error);
        });
    });
  },

  fetchSerialsRelated: ({ commit }, data) => {
    commit("setIsSerialsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.fetchSerialsRelated(data)
        .then((res) => {
          commit("setIsSerialsLoaded", true);
          commit("setItemSerials", res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsSerialsLoaded", true);
          reject(error);
        });
    });
  },

  fetchTransferSerials: ({ commit }, data) => {
    commit("setIsSerialsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.fetchTransferSerials(data)
        .then((res) => {
          commit("setIsSerialsLoaded", true);
          commit("setItemSerials", res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsSerialsLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
