import baseUrl from "@/constants/API";

export const SaleReturnAPI = {
  fetchSaleReturns(id) {
    return axios.get(baseUrl() + `/salereturn/${id}`);
  },
  fetchSalesReturns(data) {
    // return axios.get(baseUrl() + `/salereturn/all?page=${pageNumber}`);
    let page = data?.page ? data.page : data;
    let invoice_id = data?.invoice_id ? data.invoice_id : null;
    return axios.get(baseUrl() + `/salereturn/invoices/${invoice_id}?page=${page}`);
  },

  createSaleReturn(data) {
    return axios.post(baseUrl() + `/salereturn/create`, data);
  },
  updateSaleReturn(data) {
    return axios.post(baseUrl() + `/salereturn/update/${data.id}`, data.data);
  },
  deleteSaleReturn(id) {
    return axios.delete(baseUrl() + `/salereturn/delete/${id}`);
  },
  fetchSaleReturnRelated(id) {
    return axios.get(baseUrl() + `/salereturn/singlerelated/${id}`);
  },
  createSaleRelated(data) {
    return axios.post(baseUrl() + `/salereturn/createrelated`, data);
  },
  updateSaleRelated(data) {
    return axios.post(baseUrl() + `/salereturn/updaterelated/${data.id}`, data.data);
  },

  saleReturnDetails(invoice_id) {
    return axios.post(baseUrl() + `/salereturn/details/${invoice_id}`);
  },

  deleteReturnRelated(invoice_id) {
    return axios.delete(baseUrl() + `/salereturn/relateddelete/${invoice_id}`);
  },

  // deleted STUFF
  fetchDeletedInvoices(data) {
    return axios.post(baseUrl() + `/salereturn/deleted?page=${data.pageNumber}`, data.data);
  },
  fetchDeletedInvoiceDetails(invoice_id) {
    return axios.post(baseUrl() + `/salereturn/deleted/${invoice_id}`);
  },
  clearDeletedInvoices(data) {
    return axios.delete(baseUrl() + `/salereturn/deleted/clear`, data);
  },
};
