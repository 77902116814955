import baseUrl from "@/constants/API";

export const RestaurantOrderReportAPI = {
  fetchRestaurantOrderSearch(data) {
    return axios.post(baseUrl() + `/restaurant/order/get?page=${data.pageNumber}`, data.data);
  },

  fetchRestaurantOrderDetailSearch(data) {
    return axios.post(baseUrl() + `/restaurant/order/get?page=${data.pageNumber}`, data.data);
  },
};
