import baseUrl from "@/constants/API";

let companyLoginUrl =
  process.env.NODE_ENV == "production" ? process.env.VUE_APP_BASE_URL : "http://localhost:8000/api";

const companyLogin = (data) => {
  return axios.post(companyLoginUrl + "/companylogin", data);
};
const fetchDatabases = (data) => {
  return axios.post(companyLoginUrl + "/getdatabases", data);
};

const editEmail = (data) => {
  return axios.post(baseUrl() + `/company/updatemail`, data);
};
const editPassword = (data) => {
  return axios.post(baseUrl() + `/company/updatepassword`, data);
};

export { companyLogin, fetchDatabases, editEmail, editPassword };
