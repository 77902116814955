import baseUrl from "@/constants/API";
// import store from "../../store/index.js";

export const UserAPI = {
  loginUsers(database) {
    return axios.get(baseUrl(database) + "/loginusers");
  },

  login(data) {
    let database = null;
    if (data.database) {
      database = data.database;
    }
    return axios.post(baseUrl(database) + "/login", data);
  },
  forceLogin(data) {
    let database = null;
    if (data.database) {
      database = data.database;
    }
    return axios.post(baseUrl(database) + "/forcelogin", data);
  },

  fetchUser() {
    return axios.get(baseUrl() + "/user");
  },

  reloadUser() {
    return axios.get(baseUrl() + "/user/reload");
  },

  updateName(data) {
    return axios.post(baseUrl() + "/user/updatename", data);
  },
  updatePassword(data) {
    return axios.post(baseUrl() + "/user/updatepass", data);
  },
  updateQuickLinks(data) {
    return axios.put(baseUrl() + "/user/quicklinks", data);
  },

  checkAdminPass(data) {
    return axios.post(baseUrl() + "/user/adminpass", data);
  },
  checkSupportPass(data) {
    return axios.post(baseUrl() + "/user/supportpass", data);
  },

  logout() {
    return axios.post(baseUrl() + "/user/logout");
  },
};
