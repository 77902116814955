import baseUrl from "@/constants/API";

export const EntryAPI = {
  fetchEntrySearch(data) {
    return axios.post(baseUrl() + `/entry/search?page=${data.pageNumber}`, data.data);
  },
  fetchEntry(id) {
    return axios.get(baseUrl() + `/entry/${id}`);
  },

  fetchEntries(data) {
    // return axios.get(baseUrl() + `/entry/all?page=${pageNumber}`);
    let page = data?.page ? data.page : data;
    let entry_id = data?.entry_id ? data.entry_id : null;
    return axios.get(baseUrl() + `/entry/entries/${entry_id}?page=${page}`);
  },
  createEntry(data) {
    return axios.post(baseUrl() + `/entry/create`, data);
  },
  updateEntry(data) {
    return axios.post(baseUrl() + `/entry/update/${data.id}`, data.data);
  },
  deleteEntry(id) {
    return axios.delete(baseUrl() + `/entry/delete/${id}`);
  },

  drawerWithdraw(data) {
    return axios.post(baseUrl() + "/draweraction/withdraw", data);
  },
  drawerDeposit(data) {
    return axios.post(baseUrl() + "/draweraction/deposit", data);
  },
  drawerCashTransfer(data) {
    return axios.post(baseUrl() + "/draweraction/cashtransfer", data);
  },
};
