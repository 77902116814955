import { CityAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  cities: [],
  errors: [],
  isCitiesLoaded: false,
};

const getters = {
  isCitiesLoaded(state) {
    return state.isCitiesLoaded;
  },
  getCities(state) {
    return state.cities;
  },
  getCitiesErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsCitiesLoaded: (state, payload) => {
    state.isCitiesLoaded = payload;
  },
  setCities: (state, payload) => {
    state.cities = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  putCity: (state, payload) => {
    let data = state.cities;
    data.push(payload);
  },

  delCity: (state, payload) => {
    let data = state.cities;
    let index = data.findIndex((object) => {
      return object.payload === payload;
    });
    data.splice(index, 1);
  },
};

const actions = {
  fetchCities: ({ commit }) => {
    commit("setIsCitiesLoaded", false);
    return new Promise((resolve, reject) => {
      CityAPI.fetchCities()
        .then((res) => {
          commit("setCities", res.data);
          commit("setIsCitiesLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsCitiesLoaded", true);
          reject(error);
        });
    });
  },

  createCity: ({ commit }, data) => {
    commit("setIsCitiesLoaded", false);
    CityAPI.createCity(data)
      .then((res) => {
        commit("setIsCitiesLoaded", true);
        commit("putCity", res.data.data);
        commit("setErrors", "");
      })
      .catch((error) => {
        commit("setIsCitiesLoaded", true);
        let nameError = error.response.data.errors
          ? Object.values(error.response.data.errors)
          : [error.response.data.message];
        commit("setErrors", nameError);
      });
  },

  updateCity: ({ commit }, data) => {
    commit("setIsCitiesLoaded", false);
    CityAPI.updateCity(data)
      .then((res) => {
        commit("setIsCitiesLoaded", true);
        commit("setErrors", "");
      })
      .catch((error) => {
        commit("setIsCitiesLoaded", true);
        let nameError = error.response.data.errors
          ? Object.values(error.response.data.errors)
          : [error.response.data.message];
        commit("setErrors", nameError);
      });
  },
  deleteCity: ({ commit }, id) => {
    commit("setIsCitiesLoaded", false);
    return new Promise((resolve, reject) => {
      CityAPI.deleteCity(id)
        .then((res) => {
          commit("setIsCitiesLoaded", true);
          commit("delCity", id);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCitiesLoaded", true);
          reject(error);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
