import baseUrl from "@/constants/API";

export const StoreInAPI = {
  fetchStoreIn(id) {
    return axios.get(baseUrl() + `/storein/${id}`);
  },
  fetchSearchStoreIns(data) {
    return axios.post(baseUrl() + `/storein/search?page=${data.pageNumber}`, data.data);
  },

  fetchStoreIns(data) {
    // return axios.get(baseUrl() + `/storein/all?page=${pageNumber}`);
    let page = data?.page ? data.page : data;
    let invoice_id = data?.invoice_id ? data.invoice_id : null;
    return axios.get(baseUrl() + `/storein/invoices/${invoice_id}?page=${page}`);
  },
  createStoreIn(data) {
    return axios.post(baseUrl() + `/storein/create`, data);
  },
  updateStoreIn(data) {
    return axios.post(baseUrl() + `/storein/update/${data.id}`, data.data);
  },
  deleteStoreIn(id) {
    return axios.delete(baseUrl() + `/storein/delete/${id}`);
  },
  searchStoreIn(data) {
    return axios.post(baseUrl() + `/storein/search/${data}`);
  },

  storeInDetails(invoice_id) {
    return axios.post(baseUrl() + `/storein/details/${invoice_id}`);
  },

  convertToPurchase(data) {
    return axios.post(baseUrl() + `/storein/converttopurchase/${data.storeInId}`, data.data);
  },

  // deleted STUFF
  fetchDeletedInvoices(data) {
    return axios.post(baseUrl() + `/storein/deleted?page=${data.pageNumber}`, data.data);
  },
  fetchDeletedInvoiceDetails(invoice_id) {
    return axios.post(baseUrl() + `/storein/deleted/${invoice_id}`);
  },
  clearDeletedInvoices(data) {
    return axios.delete(baseUrl() + `/storein/deleted/clear`, data);
  },
};
