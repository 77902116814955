import { EmployeeAPI } from "@/helpers/Apis/index";
// import i18n from "@/plugins/i18n/i18n";

const state = {
  employees: [],
  isEmployeesLoaded: true,
  errors: [],

  salesAgents: [],
  isSalesAgentsLoaded: true,

  clientEmployees: [],

  currentPage: null,
};

const getters = {
  getSalesAgents(state) {
    return state.salesAgents;
  },
  isSalesAgentsLoaded(state) {
    return state.isSalesAgentsLoaded;
  },
  isEmployeesLoaded(state) {
    return state.isEmployeesLoaded;
  },
  getEmployees(state) {
    return state.employees;
  },
  getEmployeeErrors(state) {
    return state.errors;
  },
  getClientEmployees(state) {
    return state.clientEmployees;
  },

  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setIsEmployeesLoaded: (state, payload) => {
    state.isEmployeesLoaded = payload;
  },

  setSalesAgents: (state, payload) => {
    state.salesAgents = payload;
  },
  setIsSalesAgentsLoaded: (state, payload) => {
    state.isSalesAgentsLoaded = payload;
  },

  setEmployees: (state, payload) => {
    state.employees = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.employees.meta.current_page = payload.value;
  },

  setClientEmployees: (state, payload) => {
    state.clientEmployees = payload;
  },

  setNewUpdateEmployee(state, payload) {
    let employeeIndex = state.employees.data.findIndex((o) => o.id === payload.id);
    if (employeeIndex !== -1) {
      // state.employees.data[employeeIndex] = payload;
      state.employees.data.splice(employeeIndex, 1, { ...payload });
    } else {
      state.employees.data.unshift(payload);
    }
  },
  deleteEmployee(state, payload) {
    let employeeIndex = state.employees.data.findIndex((o) => o.id === payload);
    if (employeeIndex !== -1) {
      state.employees.data.splice(employeeIndex, 1);
    }
  },
};

const actions = {
  fetchSalesAgents: ({ commit }) => {
    commit("setIsSalesAgentsLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.fetchSalesAgents()
        .then((res) => {
          commit("setSalesAgents", res.data);
          commit("setIsSalesAgentsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSalesAgentsLoaded", true);
          reject(error);
        });
    });
  },

  fetchEmployees: ({ commit }, data) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.fetchEmployees(data)
        .then((res) => {
          commit("setEmployees", res.data);
          commit("setIsEmployeesLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          reject(error);
        });
    });
  },

  createEmployee: ({ commit, dispatch }, data) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.createEmployee(data)
        .then((res) => {
          commit("setIsEmployeesLoaded", true);
          commit("setErrors", "");

          commit("setNewUpdateEmployee", res.data.data);
          if (res.data.data.employee_job_id == 3) {
            commit("User/setEmployee", res.data.data, { root: true });
          }
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateEmployee: ({ state, commit, dispatch }, data) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.updateEmployee(data)
        .then((res) => {
          commit("setIsEmployeesLoaded", true);
          commit("setErrors", "");

          commit("setNewUpdateEmployee", res.data.data);
          if (res.data.data.employee_job_id == 3) {
            commit("User/setEmployee", res.data.data, { root: true });
          }
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  deleteEmployee: ({ commit, dispatch }, data) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.deleteEmployee(data)
        .then((res) => {
          commit("setIsEmployeesLoaded", true);
          commit("setErrors", "");

          commit("deleteEmployee", data);
          commit("User/deleteEmployee", res.data.data, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchEmployeeLoans: ({ commit }, id) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.fetchEmployeeLoans(id)
        .then((res) => {
          commit("setIsEmployeesLoaded", true);
          commit("setErrors", "");
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  giveSalary: ({ commit }, data) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.giveSalary(data)
        .then((res) => {
          commit("setIsEmployeesLoaded", true);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  giveLoan: ({ commit }, data) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.giveLoan(data)
        .then((res) => {
          commit("setIsEmployeesLoaded", true);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  clientEmployees: ({ commit }, account) => {
    commit("setIsEmployeesLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.clientEmployees(account)
        .then((res) => {
          commit("setIsEmployeesLoaded", true);
          commit("setErrors", "");
          commit("setClientEmployees", res.data.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsEmployeesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
