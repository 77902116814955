// import { InvoiceTypeIds } from "@/helpers/Constants/General";
import { toFixedIfNecessary } from "@/helpers/HelperFunctions";
import { OrderAPI } from "@/helpers/Apis/Orders/Orders";
import { EntryDetailAPI } from "@/helpers/Apis/Entries/EntryDetails";
import router from "@/router";

const state = {
  order: {},

  errors: [],
  saleOrderDetailsErrors: [],
  isSaleOrdersLoaded: true,
  isEntriesLoaded: true,
};

const getters = {
  getSaleOrder(state) {
    return state.order;
  },
  isSaleOrdersLoaded(state) {
    return state.isSaleOrdersLoaded;
  },
  getSaleOrderErrors(state) {
    return state.errors;
  },
  getSaleOrderDetailsErrors(state) {
    return state.saleOrderDetailsErrors;
  },

  isEntriesLoaded(state) {
    return state.isEntriesLoaded;
  },
};

const mutations = {
  setSaleOrder: (state, payload) => {
    state.order = payload;
  },

  setIsSaleOrdersLoaded: (state, payload) => {
    state.isSaleOrdersLoaded = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setExtraEntries(state, payload) {
    state.order.invoice_entries_extra = payload;
  },

  addInvoiceEntries(state, payload) {
    state.order.invoice_entries_extra = [...state.order.invoice_entries_extra, ...payload];
    let expenses = toFixedIfNecessary(
      state.order.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
      2
    );
    state.order.expenses = expenses;
  },
  setInvoiceExpenses(state, payload) {
    if (payload) {
      state.order.expenses = payload;
    } else {
      let expenses = toFixedIfNecessary(
        state.order.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
        2
      );
      state.order.expenses = expenses;
    }
  },
  setIsEntriesLoaded(state, payload) {
    state.isEntriesLoaded = payload;
  },
};

const actions = {
  fetchSaleOrder: ({ commit }, id) => {
    commit("setIsSaleOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      OrderAPI.fetchSaleOrder(id)
        .then((res) => {
          commit("setSaleOrder", res.data.data);
          commit("setIsSaleOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsSaleOrdersLoaded", true);
          reject(error);
        });
    });
  },

  createSaleOrder: ({ commit }, data) => {
    commit("setIsSaleOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      OrderAPI.createSaleOrder(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsSaleOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setSaleOrder", invoice);
          router.push({
            path: `/saleorders/${invoice.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSaleOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updateSaleOrder: ({ commit }, data) => {
    commit("setIsSaleOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      OrderAPI.updateSaleOrder(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsSaleOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setSaleOrder", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSaleOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
  deleteSaleOrder: ({ commit }, data) => {
    commit("setIsSaleOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      OrderAPI.deleteSaleOrder(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsSaleOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSaleOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  orderConvertToSale: ({ commit }, data) => {
    commit("setIsSaleOrdersLoaded", false);
    return new Promise((resolve, reject) => {
      OrderAPI.orderConvertToSale(data)
        .then((res) => {
          commit("setIsSaleOrdersLoaded", true);
          commit("setErrors", "");

          router.push({
            path: `/sales/${res.data.data}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsSaleOrdersLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  createCustomEntry: ({ state, commit }, data) => {
    commit("setIsEntriesLoaded", false);
    let invoiceData = state.order;
    let mergedData = { ...data, invoiceData: invoiceData };
    return new Promise((resolve, reject) => {
      EntryDetailAPI.createCustomEntry(mergedData)
        .then((res) => {
          commit("setIsEntriesLoaded", true);
          commit("setErrors", "");
          commit("addInvoiceEntries", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
