import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/axios";
import vuetify from "./plugins/vuetify";
import VueCookies from "vue-cookies";
import Vuelidate from "vuelidate";
import "./plugins/izitoast";
import i18n from "./plugins/i18n/i18n";

import "./plugins/globalComponents";
import "@/mixins/globalMixins";
import "./assets/styles/app.scss";
import "./registerServiceWorker";
Vue.use(Vuelidate);

Vue.config.productionTip = false;
Vue.prototype.$VueCookies = VueCookies;

import Echo from "laravel-echo";
import Pusher from "pusher-js";
window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: "mt1",
  wsHost: window.location.hostname,
  wsPort: 6001,
  wssPort: 443,
  enabledTransports: ["ws", "wss"],
  disableStats: true,
  forceTLS: process.env.NODE_ENV == "production" ? true : false,
  encrypted: process.env.NODE_ENV == "production" ? true : false,
});

// Object.defineProperty(Vue.prototype, "$eventBus", { value: new Vue() });
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
