import baseUrl from "@/constants/API";

export const PurchaseAPI = {
  fetchPurchase(id) {
    return axios.get(baseUrl() + `/purchase/${id}`);
  },
  createPurchase(data) {
    return axios.post(baseUrl() + `/purchase/create`, data);
  },
  fetchPurchases(data) {
    // return axios.get(baseUrl() + `/purchase/all?page=${pageNumber}`);
    let page = data?.page ? data.page : data;
    let invoice_id = data?.invoice_id ? data.invoice_id : null;
    return axios.get(baseUrl() + `/purchase/invoices/${invoice_id}?page=${page}`);
  },
  updatePurchase(data) {
    return axios.post(baseUrl() + `/purchase/update/${data.id}`, data.data);
  },
  deletePurchase(id) {
    return axios.delete(baseUrl() + `/purchase/delete/${id}`);
  },

  purchaseDetails(invoice_id) {
    return axios.post(baseUrl() + `/purchase/details/${invoice_id}`);
  },

  // deleted STUFF
  fetchDeletedInvoices(data) {
    return axios.post(baseUrl() + `/purchase/deleted?page=${data.pageNumber}`, data.data);
  },
  fetchDeletedInvoiceDetails(invoice_id) {
    return axios.post(baseUrl() + `/purchase/deleted/${invoice_id}`);
  },
  clearDeletedInvoices(data) {
    return axios.delete(baseUrl() + `/purchase/deleted/clear`, data);
  },
};
