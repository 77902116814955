import baseUrl from "@/constants/API";

const fetchBeginningStoreDetails = (data) => {
  return axios.get(
    baseUrl() + `/openstore/storedetails/${data?.data?.store_id}?page=${data.pageNumber}`
  );
};
const fetchBeginningDetails = () => {
  return axios.get(baseUrl() + "/openstore/all");
};
const createBeginningDetail = (data) => {
  return axios.post(baseUrl() + `/openstore/create`, data);
};
const updateBeginningDetail = (data) => {
  return axios.post(baseUrl() + `/openstore/update/${data.id}`, data.data);
};
const deleteBeginningDetail = (data) => {
  return axios.delete(baseUrl() + `/openstore/delete/${data}`);
};

export {
  fetchBeginningStoreDetails,
  fetchBeginningDetails,
  createBeginningDetail,
  updateBeginningDetail,
  deleteBeginningDetail,
};
