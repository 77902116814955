import { PurchaseReturnAPI } from "@/helpers/Apis/Invoices/PurchasesReturns/PurchaseReturns";
import { SaleReturnAPI } from "@/helpers/Apis/Invoices/SalesReturns/SaleReturns";
import i18n from "@/plugins/i18n/i18n";

const state = {
  relatedReturn: {},

  relatedReturnLoaded: true,
  relatedReturnErrors: [],
};

const getters = {
  getRelatedReturn(state) {
    return state.relatedReturn;
  },
  getIsRelatedReturnLoaded(state) {
    return state.relatedReturnLoaded;
  },
  getRelatedReturnErrors(state) {
    return state.relatedReturnErrors;
  },
};

const mutations = {
  setRelatedReturn: (state, payload) => {
    state.relatedReturn = payload;
  },
  setIsRelatedReturnLoaded(state, payload) {
    state.relatedReturnLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.relatedReturnErrors = payload;
  },
};

const actions = {
  SetData: ({ commit }, data) => {
    if (data) {
      commit("setRelatedReturn", data);
    } else {
      commit("setRelatedReturn", null);
    }
  },

  /////// related purchasepurchasepurchasepurchase actions
  /////// related purchasepurchasepurchasepurchase actions
  /////// related purchasepurchasepurchasepurchase actions
  /////// related purchasepurchasepurchasepurchase actions
  fetchPurchaseReturnRelated: ({ commit }, id) => {
    commit("setIsRelatedReturnLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      PurchaseReturnAPI.fetchPurchaseReturnRelated(id)
        .then((res) => {
          commit("setRelatedReturn", res.data.data);
          commit("App/setAppLoader", false, { root: true });
          commit("setIsRelatedReturnLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsRelatedReturnLoaded", true);
          reject(error);
        });
    });
  },
  createPurchaseRelated: ({ commit }, data) => {
    commit("setIsRelatedReturnLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      PurchaseReturnAPI.createPurchaseRelated(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsRelatedReturnLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setRelatedReturn", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRelatedReturnLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updatePurchaseRelated: ({ commit }, data) => {
    commit("setIsRelatedReturnLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      PurchaseReturnAPI.updatePurchaseRelated(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsRelatedReturnLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setRelatedReturn", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRelatedReturnLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  DeletePurchaseRelatedReturn: ({ commit }, data) => {
    commit("setIsRelatedReturnLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      PurchaseReturnAPI.deleteReturnRelated(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsRelatedReturnLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setRelatedReturn", null);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRelatedReturnLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  /////// related SALESSALESSALESSALESSALESSALES actions
  /////// related SALESSALESSALESSALESSALESSALES actions
  /////// related SALESSALESSALESSALESSALESSALES actions
  /////// related SALESSALESSALESSALESSALESSALES actions
  /////// related SALESSALESSALESSALESSALESSALES actions
  fetchSaleReturnRelated: ({ commit }, id) => {
    commit("setIsRelatedReturnLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      SaleReturnAPI.fetchSaleReturnRelated(id)
        .then((res) => {
          commit("setRelatedReturn", res.data.data);
          commit("setIsRelatedReturnLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRelatedReturnLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);
        });
    });
  },

  createSaleReturnRelated: ({ commit }, data) => {
    commit("setIsRelatedReturnLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      SaleReturnAPI.createSaleRelated(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsRelatedReturnLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setRelatedReturn", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRelatedReturnLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updateSaleReturnRelated: ({ commit }, data) => {
    commit("setIsRelatedReturnLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      SaleReturnAPI.updateSaleRelated(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsRelatedReturnLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setRelatedReturn", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRelatedReturnLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  DeleteSaleRelatedReturn: ({ commit }, data) => {
    commit("setIsRelatedReturnLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      SaleReturnAPI.deleteReturnRelated(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsRelatedReturnLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setRelatedReturn", null);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsRelatedReturnLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
