import { DrawerAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  drawers: [],
  errors: [],
  isDrawerLoaded: false,
  drawer: {},

  userDrawers: [],
};

const getters = {
  isDrawerLoaded(state) {
    return state.isDrawerLoaded;
  },
  getDrawers(state) {
    return state.drawers;
  },
  getDrawer(state) {
    return state.drawer;
  },
  getDrawerErrors(state) {
    return state.errors;
  },

  getUserDrawers(state) {
    return state.userDrawers;
  },
};

const mutations = {
  setIsDrawerLoading: (state, payload) => {
    state.isDrawerLoaded = payload;
  },
  setDrawers: (state, payload) => {
    state.drawers = payload;
  },
  setDrawer: (state, payload) => {
    state.drawer = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.drawers.meta.current_page = payload;
  },
  setUserDrawers(state, payload) {
    state.userDrawers = payload;
  },

  setNewUpdateDrawer(state, payload) {
    let drawerIndex = state.drawers.findIndex((o) => o.id === payload.id);
    if (drawerIndex !== -1) {
      // state.drawers[drawerIndex] = payload;
      state.drawers.splice(drawerIndex, 1, { ...payload });
    } else {
      state.drawers.unshift(payload);
    }
  },
  deleteDrawer(state, payload) {
    let drawerIndex = state.drawers.findIndex((o) => o.id === payload);
    // Check if drawer is found
    if (drawerIndex !== -1) {
      state.drawers.splice(drawerIndex, 1); // Remove drawer
    }
  },
};

const actions = {
  fetchDrawer: ({ commit }) => {
    commit("setIsDrawerLoading", false);
    return new Promise((resolve, reject) => {
      DrawerAPI.fetchDrawer()
        .then((res) => {
          commit("setDrawer", res.data);
          commit("setIsDrawerLoading", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsDrawerLoading", true);
          reject(error);
        });
    });
  },
  fetchDrawers: ({ commit }) => {
    commit("setIsDrawerLoading", false);
    return new Promise((resolve, reject) => {
      DrawerAPI.fetchDrawers()
        .then((res) => {
          commit("setDrawers", res.data.data);
          commit("setIsDrawerLoading", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsDrawerLoading", true);
          reject(error);
        });
    });
  },

  createDrawer: ({ commit, dispatch }, data) => {
    commit("setIsDrawerLoading", false);
    return new Promise((resolve, reject) => {
      DrawerAPI.createDrawer(data)
        .then((res) => {
          commit("setIsDrawerLoading", true);
          commit("setErrors", "");
          commit("setNewUpdateDrawer", res.data.data);
          commit("User/setAssociate", { data: res.data.data, type: "drawers" }, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsDrawerLoading", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateDrawer: ({ commit, dispatch }, data) => {
    commit("setIsDrawerLoading", false);
    return new Promise((resolve, reject) => {
      DrawerAPI.updateDrawer(data)
        .then((res) => {
          commit("setIsDrawerLoading", true);
          commit("setErrors", "");
          commit("setNewUpdateDrawer", res.data.data);
          commit("User/setAssociate", { data: res.data.data, type: "drawers" }, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsDrawerLoading", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteDrawer: ({ commit }, id) => {
    commit("setIsDrawerLoading", false);
    return new Promise((resolve, reject) => {
      DrawerAPI.deleteDrawer(id)
        .then((res) => {
          commit("setErrors", "");
          commit("deleteDrawer", id);
          commit("User/deleteAssociate", { data: data, type: "drawers" }, { root: true });
          commit("setIsDrawerLoading", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsDrawerLoading", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchBranchDrawers: ({ commit }, id) => {
    commit("setIsDrawerLoading", false);
    return new Promise((resolve, reject) => {
      DrawerAPI.fetchBranchDrawers(id)
        .then((res) => {
          commit("setIsDrawerLoading", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsDrawerLoading", true);
          reject(error);
        });
    });
  },

  fetchUserDrawers: ({ commit }) => {
    commit("setIsDrawerLoading", false);
    return new Promise((resolve, reject) => {
      DrawerAPI.fetchUserDrawers()
        .then((res) => {
          commit("setUserDrawers", res.data.data);
          commit("setIsDrawerLoading", true);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setIsDrawerLoading", true);
          reject(error);
        });
    });
  },

  fetchBasicDrawers: ({ commit }) => {
    commit("setIsDrawerLoading", false);
    return new Promise((resolve, reject) => {
      DrawerAPI.fetchBasicDrawers()
        .then((res) => {
          commit("setDrawers", res.data);
          commit("setIsDrawerLoading", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsDrawerLoading", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
