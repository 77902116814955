import { StateAPI, CityAPI, DistrictAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  states: [],
  errors: [],
  isStatesLoaded: false,
};

const getters = {
  isStatesLoaded(state) {
    return state.isStatesLoaded;
  },
  getStates(state) {
    return state.states;
  },
  getCities(state) {
    let states = state.states;
    return states.flatMap(({ name: stateName, cities }) =>
      cities.map((city) => ({ stateName, ...city }))
    );
  },
  getDistricts(state) {
    return (state?.states || []).flatMap((state) =>
      (state.cities || []).flatMap((city) =>
        (city.districts || []).map((district) => ({
          id: district.id || "",
          state_id: state.id || "",
          stateName: state.name || "",
          city_id: city.id || "",
          cityName: city.name || "",
          name: district.name || "",
        }))
      )
    );
  },
  getStatesErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsStatesLoaded: (state, payload) => {
    state.isStatesLoaded = payload;
  },
  setStates: (state, payload) => {
    state.states = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  putState: (state, payload) => {
    let data = state.states;
    payload.cities = [];
    data.push(payload);
  },

  delState: (state, payload) => {
    let data = state.states;
    let index = data.findIndex((object) => {
      return object.id === payload;
    });
    data.splice(index, 1);
  },

  putCity: (state, payload) => {
    let states = state.states;
    let stateIndex = states.findIndex((object) => {
      return object.id === payload.state_id;
    });
    payload.districts = [];
    states[stateIndex].cities.push(payload);
  },

  delCity: (state, payload) => {
    let states = state.states;
    let stateIndex = states.findIndex((object) => {
      return object.id === payload.state_id;
    });
    let stateVar = states[stateIndex];
    let cityIndex = stateVar.cities.findIndex((object) => {
      return object.id === payload.id;
    });
    stateVar.cities.splice(cityIndex, 1);
  },

  putDistrict: (state, payload) => {
    let states = state.states;
    let stateIndex = states.findIndex((object) => {
      return object.id === payload.state_id;
    });
    let stateVar = states[stateIndex];
    let cityIndex = stateVar.cities.findIndex((object) => {
      return object.id === payload.city_id;
    });
    let city = states[stateIndex].cities[cityIndex];
    city.districts.push(payload);
  },

  delDistrict: (state, payload) => {
    let states = state.states;
    let stateIndex = states.findIndex((object) => {
      return object.id === payload.state_id;
    });
    let stateVar = states[stateIndex];
    let cityIndex = stateVar.cities.findIndex((object) => {
      return object.id === payload.city_id;
    });
    let city = stateVar.cities[cityIndex];
    let districtIndex = city.districts.findIndex((object) => {
      return object.id === payload.id;
    });
    city.districts.splice(districtIndex, 1);
  },
};

const actions = {
  fetchStatesBasic: ({ commit }) => {
    commit("setIsStatesLoaded", false);
    return new Promise((resolve, reject) => {
      StateAPI.fetchStatesBasic()
        .then((res) => {
          commit("setStates", res.data);
          commit("setIsStatesLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsStatesLoaded", true);
          reject(error);
        });
    });
  },

  fetchStates: ({ commit }) => {
    commit("setIsStatesLoaded", false);
    return new Promise((resolve, reject) => {
      StateAPI.fetchStates()
        .then((res) => {
          commit("setStates", res.data);
          commit("setIsStatesLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsStatesLoaded", true);
          reject(error);
        });
    });
  },

  createState: ({ commit }, data) => {
    commit("setIsStatesLoaded", false);
    StateAPI.createState(data)
      .then((res) => {
        commit("setIsStatesLoaded", true);
        commit("putState", res.data.data);
        commit("setErrors", "");
      })
      .catch((error) => {
        commit("setIsStatesLoaded", true);
        let nameError = error.response.data.errors
          ? Object.values(error.response.data.errors)
          : [error.response.data.message];
        commit("setErrors", nameError);
      });
  },

  updateState: ({ commit }, data) => {
    commit("setIsStatesLoaded", false);
    StateAPI.updateState(data)
      .then((res) => {
        commit("setIsStatesLoaded", true);
        commit("setErrors", "");
      })
      .catch((error) => {
        commit("setIsStatesLoaded", true);
        let nameError = error.response.data.errors
          ? Object.values(error.response.data.errors)
          : [error.response.data.message];
        commit("setErrors", nameError);
      });
  },
  deleteState: ({ commit }, id) => {
    commit("setIsStatesLoaded", false);
    return new Promise((resolve, reject) => {
      StateAPI.deleteState(id)
        .then((res) => {
          commit("setIsStatesLoaded", true);
          commit("delState", id);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStatesLoaded", true);
          reject(error);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  createCity: ({ commit }, data) => {
    commit("setIsStatesLoaded", false);
    CityAPI.createCity(data)
      .then((res) => {
        commit("setIsStatesLoaded", true);
        commit("putCity", res.data.data);
        commit("setErrors", "");
      })
      .catch((error) => {
        commit("setIsStatesLoaded", true);
        let nameError = error.response.data.errors
          ? Object.values(error.response.data.errors)
          : [error.response.data.message];
        commit("setErrors", nameError);
      });
  },

  updateCity: ({ commit }, data) => {
    commit("setIsStatesLoaded", false);
    CityAPI.updateCity(data)
      .then((res) => {
        commit("setIsStatesLoaded", true);
        commit("setErrors", "");
      })
      .catch((error) => {
        commit("setIsStatesLoaded", true);
        let nameError = error.response.data.errors
          ? Object.values(error.response.data.errors)
          : [error.response.data.message];
        commit("setErrors", nameError);
      });
  },
  deleteCity: ({ commit }, data) => {
    commit("setIsStatesLoaded", false);
    return new Promise((resolve, reject) => {
      CityAPI.deleteCity(data.id)
        .then((res) => {
          commit("setIsStatesLoaded", true);
          commit("delCity", data);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStatesLoaded", true);
          reject(error);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  createDistrict: ({ commit }, payload) => {
    commit("setIsStatesLoaded", false);
    return new Promise((resolve, reject) => {
      DistrictAPI.createDistrict(payload)
        .then((res) => {
          let district = res.data.data;
          district.state_id = payload.state_id;
          commit("setIsStatesLoaded", true);
          commit("putDistrict", district);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStatesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateDistrict: ({ commit }, data) => {
    commit("setIsStatesLoaded", false);
    return new Promise((resolve, reject) => {
      DistrictAPI.updateDistrict(data)
        .then((res) => {
          commit("setIsStatesLoaded", true);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStatesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteDistrict: ({ commit }, data) => {
    commit("setIsStatesLoaded", false);
    return new Promise((resolve, reject) => {
      DistrictAPI.deleteDistrict(data.id)
        .then((res) => {
          commit("setIsStatesLoaded", true);
          commit("setErrors", "");
          commit("delDistrict", data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsStatesLoaded", true);
          reject(error);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
