import { EmployeeAPI } from "@/helpers/Apis/index";
// import i18n from "@/plugins/i18n/i18n";

const state = {
  couriers: [],
  isCouriersLoaded: true,
};

const getters = {
  getCouriers(state) {
    return state.couriers;
  },
  isCouriersLoaded(state) {
    return state.isCouriersLoaded;
  },
};

const mutations = {
  setCouriers: (state, payload) => {
    state.couriers = payload;
  },
  setIsCouriersLoaded: (state, payload) => {
    state.isCouriersLoaded = payload;
  },
};

const actions = {
  fetchCouriers: ({ commit }, branchId) => {
    commit("setIsCouriersLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeAPI.fetchCouriers(branchId)
        .then((res) => {
          commit("setCouriers", res.data);
          commit("setIsCouriersLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCouriersLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
