// import { InvoiceTypeIds } from "@/helpers/Constants/General";
import { toFixedIfNecessary } from "@/helpers/HelperFunctions";
import * as APIs from "@/helpers/Apis/Productions/FreeProductions";
import { EntryDetailAPI } from "@/helpers/Apis/Entries/EntryDetails";
import i18n from "@/plugins/i18n/i18n";
import router from "@/router";

const state = {
  production: {},

  errors: [],
  productionDetailsErrors: [],
  isProductionLoaded: true,
  isEntriesLoaded: true,
};

const getters = {
  getProduction(state) {
    return state.production;
  },

  isProductionLoaded(state) {
    return state.isProductionLoaded;
  },

  getProductionErrors(state) {
    return state.errors;
  },

  getProductionDetailsErrors(state) {
    return state.productionDetailsErrors;
  },

  isEntriesLoaded(state) {
    return state.isEntriesLoaded;
  },
};

const mutations = {
  setProduction: (state, payload) => {
    state.production = payload;
  },

  setIsProductionLoaded: (state, payload) => {
    state.isProductionLoaded = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setExtraEntries(state, payload) {
    state.production.invoice_entries_extra = payload;
  },

  setNewDetailPrices(state, payload) {
    let details = state.production.invoice_details;

    payload.forEach((el) => {
      let detailIndex = details.findIndex((o) => {
        return o.id === el.id;
      });
      state.production.invoice_details[detailIndex].price = el.price;
      state.production.invoice_details[detailIndex].total = el.total;
    });
  },

  addInvoiceEntries(state, payload) {
    state.production.invoice_entries_extra = [
      ...state.production.invoice_entries_extra,
      ...[payload],
    ];
    let expenses = toFixedIfNecessary(
      state.production.invoice_entries_extra.reduce((a, b) => a + +b["credit"], 0) ?? 0,
      2
    );
    state.production.expenses = expenses;
  },
  setInvoiceExpenses(state, payload) {
    if (payload) {
      state.production.expenses = payload;
    } else {
      let expenses = toFixedIfNecessary(
        state.production.invoice_entries_extra.reduce((a, b) => a + +b["credit"], 0) ?? 0,
        2
      );
      state.production.expenses = expenses;
    }
  },
  setIsEntriesLoaded(state, payload) {
    state.isEntriesLoaded = payload;
  },
};

const actions = {
  FreeProduction: ({ commit }, id) => {
    commit("setIsProductionLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      APIs.getFreeProduction(id)
        .then((res) => {
          commit("setProduction", res.data.data);
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsProductionLoaded", true);
          reject(error);
        });
    });
  },

  createFreeProduction: ({ commit }, data) => {
    commit("setIsProductionLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      APIs.createFreeProduction(data)
        .then((res) => {
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");

          let invoice = res.data.data;
          commit("setProduction", invoice);
          router.push({
            path: `/freeproductions/${invoice.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updateFreeProduction: ({ commit }, data) => {
    commit("setIsProductionLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      APIs.updateFreeProduction(data)
        .then((res) => {
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");

          let invoice = res.data.data;
          commit("setProduction", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
  deleteProduction: ({ commit }, data) => {
    commit("setIsProductionLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      APIs.deleteProduction(data)
        .then((res) => {
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsProductionLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  createProdEntry: ({ state, commit }, data) => {
    commit("setIsEntriesLoaded", false);
    let invoiceData = state.production;
    let mergedData = { ...data, invoiceData: invoiceData };
    return new Promise((resolve, reject) => {
      EntryDetailAPI.createProdEntry(mergedData)
        .then((res) => {
          commit("setIsEntriesLoaded", true);
          commit("setErrors", "");
          state.production.expenses = res.data.data[0].expenses;
          commit("setNewDetailPrices", res.data.data[1]);
          commit("addInvoiceEntries", res.data.data[2]);
          resolve(res.data.data[0]);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteProdEntry: ({ state, commit }, id) => {
    commit("setIsEntriesLoaded", false);
    return new Promise((resolve, reject) => {
      EntryDetailAPI.deleteProdEntry(id)
        .then((res) => {
          commit("setIsEntriesLoaded", true);
          commit("setErrors", "");
          state.production.expenses = res.data.data[0].expenses;
          commit("setNewDetailPrices", res.data.data[1]);
          resolve(res.data.data[0]);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
