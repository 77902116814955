// import { InvoiceTypeIds } from "@/helpers/Constants/General";
import { toFixedIfNecessary } from "@/helpers/HelperFunctions";
import { OrderAPI } from "@/helpers/Apis/Orders/Orders";
import { EntryDetailAPI } from "@/helpers/Apis/Entries/EntryDetails";
import router from "@/router";

const state = {
  order: {},

  errors: [],
  purchaseOrderDetailsErrors: [],
  isPurchaseOrdersLoaded: true,
  isEntriesLoaded: true,
};

const getters = {
  getPurchaseOrder(state) {
    return state.order;
  },
  isPurchaseOrdersLoaded(state) {
    return state.isPurchaseOrdersLoaded;
  },
  getPurchaseOrderErrors(state) {
    return state.errors;
  },
  getPurchaseOrderDetailsErrors(state) {
    return state.purchaseOrderDetailsErrors;
  },

  isEntriesLoaded(state) {
    return state.isEntriesLoaded;
  },
};

const mutations = {
  setPurchaseOrder: (state, payload) => {
    state.order = payload;
  },

  setIsPurchaseOrdersLoaded: (state, payload) => {
    state.isPurchaseOrdersLoaded = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setExtraEntries(state, payload) {
    state.order.invoice_entries_extra = payload;
  },

  addInvoiceEntries(state, payload) {
    state.order.invoice_entries_extra = [...state.order.invoice_entries_extra, ...payload];
    let expenses = toFixedIfNecessary(
      state.order.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
      2
    );
    state.order.expenses = expenses;
  },
  setInvoiceExpenses(state, payload) {
    if (payload) {
      state.order.expenses = payload;
    } else {
      let expenses = toFixedIfNecessary(
        state.order.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
        2
      );
      state.order.expenses = expenses;
    }
  },
  setIsEntriesLoaded(state, payload) {
    state.isEntriesLoaded = payload;
  },
};

const actions = {
  fetchPurchaseOrder: ({ commit }, id) => {
    commit("setIsPurchaseOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      OrderAPI.fetchPurchaseOrder(id)
        .then((res) => {
          commit("setPurchaseOrder", res.data.data);
          commit("setIsPurchaseOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsPurchaseOrdersLoaded", true);
          reject(error);
        });
    });
  },

  createPurchaseOrder: ({ commit }, data) => {
    commit("setIsPurchaseOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      OrderAPI.createPurchaseOrder(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsPurchaseOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setPurchaseOrder", invoice);
          router.push({
            path: `/purchaseorders/${invoice.id}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsPurchaseOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updatePurchaseOrder: ({ commit }, data) => {
    commit("setIsPurchaseOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      OrderAPI.updatePurchaseOrder(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsPurchaseOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setPurchaseOrder", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsPurchaseOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
  deletePurchaseOrder: ({ commit }, data) => {
    commit("setIsPurchaseOrdersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      OrderAPI.deletePurchaseOrder(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsPurchaseOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsPurchaseOrdersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  orderConvertToPurchase: ({ commit }, data) => {
    commit("setIsPurchaseOrdersLoaded", false);
    return new Promise((resolve, reject) => {
      OrderAPI.orderConvertToPurchase(data)
        .then((res) => {
          commit("setIsPurchaseOrdersLoaded", true);
          commit("setErrors", "");

          router.push({
            path: `/purchases/${res.data.data}`,
          });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsPurchaseOrdersLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  createCustomEntry: ({ state, commit }, data) => {
    commit("setIsEntriesLoaded", false);
    let invoiceData = state.order;
    let mergedData = { ...data, invoiceData: invoiceData };
    return new Promise((resolve, reject) => {
      EntryDetailAPI.createCustomEntry(mergedData)
        .then((res) => {
          commit("setIsEntriesLoaded", true);
          commit("setErrors", "");
          commit("addInvoiceEntries", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
