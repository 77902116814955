var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({staticClass:"elevation-1",class:_vm.isStriped ? 'strip_table' : '',attrs:{"loading-text":_vm.$t('loading'),"no-data-text":_vm.$t('nodata'),"dense":"","hide-default-footer":_vm.hideDefaultFooter,"items-per-page":_vm.itemsPerPage,"mobile-breakpoint":"0"},on:{"input":_vm.handleInput},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._t("top")]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',{class:[
        _vm.value.some(function (selected) { return selected.id == item.id; })
          ? 'v-data-table__selected'
          : '',
        _vm.getRowClass(item, index) ],style:(_vm.getRowStyle),on:{"click":function($event){return _vm.rowClicked(item, index)}}},_vm._l((_vm.headers),function(header){return _c('td',{key:header.value,staticClass:"tableColClass text-center",class:header.colClass || ''},[(_vm.$scopedSlots[("item." + (header.value))])?[_vm._t(("item." + (header.value)),null,{"item":item,"index":index})]:[_c('span',{class:_vm.getColumnClass(item, header)},[_vm._v(" "+_vm._s(_vm.getCustomValue(item, header.value, index))+" ")])]],2)}),0)]}},_vm._l((_vm.customHeaders),function(header){return {key:("item." + (header.key)),fn:function(slotProps){return [_vm._t(("item." + (header.key)),null,null,slotProps)]}}}),{key:"body.append",fn:function(ref){
      var items = ref.items;
      var headers = ref.headers;
return [(_vm.shouldShowTotals)?_c('tr',{staticClass:"totals_column text-center"},_vm._l((headers),function(header,i){return _c('td',{key:i},[(_vm.sumColumns.includes(header.keyValue))?_c('h3',[_vm._v(" "+_vm._s(_vm.calculateSum(header.value, items))+" ")]):_vm._e()])}),0):_vm._e(),_vm._t("body.append",null,null,{ items: items, headers: headers })]}},{key:"item.data-table-expand",fn:function(slotProps){return [_vm._t("item.data-table-expand",null,null,slotProps)]}},{key:"expanded-item",fn:function(slotProps){return [_vm._t("expanded-item",null,null,slotProps)]}},{key:"footer",fn:function(slotProps){return [_vm._t("footer",null,null,slotProps)]}}],null,true)},'v-data-table',Object.assign({}, _vm.$attrs, _vm.$props),false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }