import baseUrl from "@/constants/API";

export const SupplierAPI = {
  fetchSupplier(data) {
    return axios.get(baseUrl() + `/suppliers/${data.id}`);
  },
  fetchSuppliers(data) {
    return axios.post(baseUrl() + `/suppliers/get?page=${data.pageNumber}`, data.data);
  },

  createSupplier(data) {
    return axios.post(baseUrl() + `/suppliers/create`, data);
  },
  updateSupplier(data) {
    return axios.post(baseUrl() + `/suppliers/update/${data.id}`, data.data);
  },
  deleteSupplier(data) {
    return axios.delete(baseUrl() + `/suppliers/delete/${data}`);
  },

  fetchSuppliersPayable(data) {
    return axios.post(baseUrl() + `/suppliers/supplierspayable?page=${data.pageNumber}`, data.data);
    // return axios.post(baseUrl() + "/suppliers/supplierspayable", data);
  },

  fetchSupplierBalance(id) {
    return axios.get(baseUrl() + `/suppliers/balance/${id}`);
  },
};
