import { toFixedIfNecessary } from "@/helpers/HelperFunctions";
import { CashierAPI } from "@/helpers/Apis/Invoices/Cashier/Cashier";
import { EntryDetailAPI } from "@/helpers/Apis/Entries/EntryDetails";
import i18n from "@/plugins/i18n/i18n";
import router from "@/router";

const state = {
  cashier: {},
  isCashiersLoaded: true,

  errors: [],
  cashierDetailsErrors: [],
  isEntriesLoaded: true,
};

const getters = {
  getCashier(state) {
    return state.cashier;
  },

  isCashiersLoaded(state) {
    return state.isCashiersLoaded;
  },

  getCashiersErrors(state) {
    return state.errors;
  },

  getCashiersDetailsErrors(state) {
    return state.cashierDetailsErrors;
  },

  isEntriesLoaded(state) {
    return state.isEntriesLoaded;
  },
};

const mutations = {
  setCashier: (state, payload) => {
    state.cashier = payload;
  },

  setIsCashiersLoaded: (state, payload) => {
    state.isCashiersLoaded = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setCashierRelated(state, payload) {
    state.cashier.related_return = payload;
  },
  setExtraEntries(state, payload) {
    state.cashier.invoice_entries_extra = payload;
  },

  addInvoiceEntries(state, payload) {
    state.cashier.invoice_entries_extra = [...state.cashier.invoice_entries_extra, ...payload];
    let expenses = toFixedIfNecessary(
      state.cashier.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
      2
    );
    state.cashier.expenses = expenses;
  },
  setInvoiceExpenses(state, payload) {
    if (payload) {
      state.cashier.expenses = payload;
    } else {
      let expenses = toFixedIfNecessary(
        state.cashier.invoice_entries_extra.reduce((a, b) => a + +b["debit"], 0) ?? 0,
        2
      );
      state.cashier.expenses = expenses;
    }
  },
  setIsEntriesLoaded(state, payload) {
    state.isEntriesLoaded = payload;
  },
};

const actions = {
  fetchCashier: ({ commit }, id) => {
    commit("setIsCashiersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      CashierAPI.fetchCashier(id)
        .then((res) => {
          commit("setCashier", res.data.data);
          commit("setErrors", "");
          commit("setIsCashiersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsCashiersLoaded", true);
          reject(error);
        });
    });
  },

  createCashier: ({ commit }, data) => {
    commit("setIsCashiersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      CashierAPI.createCashier(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsCashiersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCashiersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = [];
          if (error.response.data.errors) {
            nameError = Object.values(error.response.data.errors);
            commit("setErrors", nameError);
          } else if (error.response.data.message) {
            commit("setErrors", [error.response.data.message]);
          }
        });
    });
  },

  updateCashier: ({ commit }, data) => {
    commit("setIsCashiersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      CashierAPI.updateCashier(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsCashiersLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let invoice = res.data.data;
          commit("setCashier", invoice);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCashiersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);

          let nameError = [];
          if (error.response.data.errors) {
            nameError = Object.values(error.response.data.errors);
            commit("setErrors", nameError);
          } else if (error.response.data.message) {
            commit("setErrors", [error.response.data.message]);
          }
        });
    });
  },
  deleteCashier: ({ commit }, data) => {
    commit("setIsCashiersLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      CashierAPI.deleteCashier(data)
        .then((res) => {
          commit("setIsCashiersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsCashiersLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);
        });
    });
  },

  createCustomEntry: ({ state, commit }, data) => {
    commit("setIsEntriesLoaded", false);
    let invoiceData = state.cashier;
    let mergedData = { ...data, invoiceData: invoiceData };
    return new Promise((resolve, reject) => {
      EntryDetailAPI.createCustomEntry(mergedData)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsEntriesLoaded", true);
          commit("addInvoiceEntries", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEntriesLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
