import * as APIs from "@/helpers/Apis/Productions/ProductionProportions/ProductionProportions";
import i18n from "@/plugins/i18n/i18n";

const state = {
  errors: [],
  isProductionProportionItemLoaded: true,
};

const getters = {
  isProductionProportionItemLoaded(state) {
    return state.isProductionProportionItemLoaded;
  },

  getBranchErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsProductionProportionItemLoaded: (state, payload) => {
    state.isProductionProportionItemLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  deleteProductionProportionItem: ({ commit }, data) => {
    commit("setIsProductionProportionItemLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.deleteProductionProportionItem(data)
        .then((res) => {
          commit("setIsProductionProportionItemLoaded", true);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsProductionProportionItemLoaded", true);
          reject(error);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
