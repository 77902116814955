import * as APIs from "@/helpers/Apis/AccType";
import i18n from "@/plugins/i18n/i18n";

const state = {
  acctypes: [],
  errors: [],
  isAccTypesLoaded: false,
};

const getters = {
  isAccTypesLoaded(state) {
    return state.isAccTypesLoaded;
  },
  getAccTypes(state) {
    return state.acctypes;
  },
  getAccTypeErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsAccTypesLoaded: (state, payload) => {
    state.isAccTypesLoaded = payload;
  },
  setAccTypes: (state, payload) => {
    state.acctypes = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  fetchAccTypes: ({ commit }) => {
    commit("setIsAccTypesLoaded", false);
    APIs.fetchAccTypes()
      .then((response) => {
        commit("setAccTypes", response.data);
        commit("setIsAccTypesLoaded", true);
      })
      .catch((error) => {
        commit("setIsAccTypesLoaded", true);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
