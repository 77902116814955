import { ReceivableAPI } from "@/helpers/Apis/index.js";
import i18n from "@/plugins/i18n/i18n";

const state = {
  receivables: [],
  errors: [],
  isReceivableLoaded: true,

  receivable: {},
  receivableEntries: [],
};

const getters = {
  getReceivables(state) {
    return state.receivables;
  },
  isReceivableLoaded(state) {
    return state.isReceivableLoaded;
  },
  getReceivable(state) {
    return state.receivable;
  },
  getReceivableEntries(state) {
    return state.receivableEntries;
  },
  getReceivableErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsReceivableLoaded: (state, payload) => {
    state.isReceivableLoaded = payload;
  },
  setReceivables: (state, payload) => {
    state.receivables = payload;
  },
  setReceivable: (state, payload) => {
    state.receivable = payload;
  },
  setReceivableEntries: (state, payload) => {
    state.receivableEntries = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    // state.receivables.meta.current_page = payload.value;
    let collection = payload.collection;
    state[`${collection}`].meta.current_page = payload.value;
  },
};

const actions = {
  fetchReceivable: ({ commit }, id) => {
    commit("setIsReceivableLoaded", false);
    return new Promise((resolve, reject) => {
      ReceivableAPI.fetchReceivable(id)
        .then((res) => {
          commit("setReceivable", res.data);
          commit("setIsReceivableLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsReceivableLoaded", true);
          reject(error.response);
        });
    });
  },
  fetchReceivables: ({ commit }, data) => {
    commit("setIsReceivableLoaded", false);
    return new Promise((resolve, reject) => {
      ReceivableAPI.fetchReceivables(data)
        .then((res) => {
          commit("setReceivables", res.data);
          commit("setIsReceivableLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsReceivableLoaded", true);
          reject(error.response);
        });
    });
  },

  createReceivable: ({ commit, dispatch }, data) => {
    commit("setIsReceivableLoaded", false);
    return new Promise((resolve, reject) => {
      ReceivableAPI.CreateNote(data)
        .then((res) => {
          commit("setIsReceivableLoaded", true);
          commit("setErrors", "");
          // dispatch("fetchReceivables");
          resolve();
        })
        .catch((error) => {
          commit("setIsReceivableLoaded", true);
          reject(error.response);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  // updateReceivable: ({ commit, dispatch }, data) => {
  //   commit("setIsReceivableLoaded", false);
  //   const response = ReceivableAPI.updateReceivable(data)
  //     .then((response) => {
  //       commit("setIsReceivableLoaded", true);

  //       if (response.status == 200) {
  //         commit("setErrors", "");
  //         dispatch("fetchReceivables");
  //       }
  //     })
  //     .catch((error) => {
  //       commit("setIsReceivableLoaded", true);

  //       let nameError = error.response.data.errors
  //         ? Object.values(error.response.data.errors)
  //         : [error.response.data.message];
  //       commit("setErrors", nameError);
  //     });
  // },

  deleteReceivable: ({ commit, dispatch }, data) => {
    commit("setIsReceivableLoaded", false);
    return new Promise((resolve, reject) => {
      ReceivableAPI.deleteNote(data)
        .then((response) => {
          if (response.status == 200) {
            commit("setErrors", "");
            // dispatch("fetchReceivables");
          }
          commit("setIsReceivableLoaded", true);
          resolve(response);
        })
        .catch((error) => {
          commit("setIsReceivableLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  ReceivableInvoiceNotes: ({ commit }, data) => {
    commit("setIsReceivableLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      ReceivableAPI.getInvoiceNotes(data)
        .then((res) => {
          commit("setReceivableEntries", res.data.data);
          commit("setIsReceivableLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setIsReceivableLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);
        });
    });
  },

  receivableEntries: ({ commit }, id) => {
    commit("setIsReceivableLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      ReceivableAPI.getNoteEntries(id)
        .then((response) => {
          commit("setReceivableEntries", response.data.data);
          commit("setIsReceivableLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(response.data.data);
        })
        .catch((error) => {
          commit("setIsReceivableLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);
        });
    });
  },

  bankCollect: ({ commit }, data) => {
    commit("setIsReceivableLoaded", false);
    return new Promise((resolve, reject) => {
      ReceivableAPI.bankCollect(data)
        .then((response) => {
          commit("setIsReceivableLoaded", true);
          resolve(response);
        })
        .catch((error) => {
          commit("setIsReceivableLoaded", true);
          reject(error);
        });
    });
  },

  noteReturned: ({ commit }, data) => {
    commit("setIsReceivableLoaded", false);
    return new Promise((resolve, reject) => {
      ReceivableAPI.noteReturned(data)
        .then((response) => {
          commit("setIsReceivableLoaded", true);
          resolve(response);
        })
        .catch((error) => {
          commit("setIsReceivableLoaded", true);
          reject(error);
        });
    });
  },

  drawerCollect: ({ commit }, data) => {
    commit("setIsReceivableLoaded", false);
    return new Promise((resolve, reject) => {
      ReceivableAPI.drawerCollect(data)
        .then((response) => {
          commit("setIsReceivableLoaded", true);
          resolve(response);
        })
        .catch((error) => {
          commit("setIsReceivableLoaded", true);
          reject(error);
        });
    });
  },

  collectedToAnother: ({ commit }, data) => {
    commit("setIsReceivableLoaded", false);
    return new Promise((resolve, reject) => {
      ReceivableAPI.collectedToAnother(data)
        .then((response) => {
          commit("setIsReceivableLoaded", true);
          resolve(response);
        })
        .catch((error) => {
          commit("setIsReceivableLoaded", true);
          reject(error);
        });
    });
  },

  deleteEntry: ({ commit }, id) => {
    commit("setIsReceivableLoaded", false);
    return new Promise((resolve, reject) => {
      ReceivableAPI.deleteEntry(id)
        .then((response) => {
          commit("setIsReceivableLoaded", true);
          resolve(response);
        })
        .catch((error) => {
          commit("setIsReceivableLoaded", true);
          reject(error);
        });
    });
  },

  createReceivableInvoiceNotes: ({ commit }, data) => {
    commit("setIsReceivableLoaded", false);
    return new Promise((resolve, reject) => {
      ReceivableAPI.createInvoiceNotes(data)
        .then((response) => {
          commit("setIsReceivableLoaded", true);
          resolve(response);
        })
        .catch((error) => {
          commit("setIsReceivableLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
