import baseUrl from "@/constants/API";

export const SaleAPI = {
  fetchSale(id) {
    return axios.get(baseUrl() + `/sale/${id}`);
  },
  createSale(data) {
    return axios.post(baseUrl() + `/sale/create`, data);
  },
  fetchSales(data) {
    // return axios.get(baseUrl() + `/sale/all?page=${pageNumber}`);
    let page = data?.page ? data.page : data;
    let invoice_id = data?.invoice_id ? data.invoice_id : null;
    return axios.get(baseUrl() + `/sale/invoices/${invoice_id}?page=${page}`);
  },
  updateSale(data) {
    return axios.post(baseUrl() + `/sale/update/${data.id}`, data.data);
  },
  deleteSale(id) {
    return axios.delete(baseUrl() + `/sale/delete/${id}`);
  },

  saleDetails(invoice_id) {
    return axios.post(baseUrl() + `/sale/details/${invoice_id}`);
  },

  // deleted STUFF
  fetchDeletedInvoices(data) {
    return axios.post(baseUrl() + `/sale/deleted?page=${data.pageNumber}`, data.data);
  },
  fetchDeletedInvoiceDetails(invoice_id) {
    return axios.post(baseUrl() + `/sale/deleted/${invoice_id}`);
  },
  clearDeletedInvoices(data) {
    return axios.delete(baseUrl() + `/sale/deleted/clear`, data);
  },
};
