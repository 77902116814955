import { SupplierAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  data: [],
  errors: [],
  isSupplierReportLoaded: true,
};

const getters = {
  isSupplierReportLoaded(state) {
    return state.isSupplierReportLoaded;
  },
  getReportData(state) {
    return state.data;
  },
  getErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsSupplierReportLoaded: (state, payload) => {
    state.isSupplierReportLoaded = payload;
  },
  setData: (state, payload) => {
    state.data = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  fetchSuppliersPayable: ({ commit }, data) => {
    commit("setIsSupplierReportLoaded", false);
    SupplierAPI.fetchSuppliersPayable(data)
      .then((response) => {
        commit("setData", response.data);
        commit("setIsSupplierReportLoaded", true);
        commit("setErrors", "");
      })
      .catch((error) => {
        commit("setIsSupplierReportLoaded", true);
        let nameError = Object.values(error.response.data.errors);
        commit("setErrors", nameError[0]);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
