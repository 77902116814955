import * as APIs from "@/helpers/Apis/Item/Serials";
import i18n from "@/plugins/i18n/i18n";

const state = {
  serials: [],
  isDataLoaded: true,
  errors: [],
};

const getters = {
  getSerials(state) {
    return state.serials;
  },
  getIsSerialsLoaded(state) {
    return state.isDataLoaded;
  },
  getErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setSerials: (state, payload) => {
    state.serials = payload;
  },

  setIsSerialsLoaded(state, payload) {
    state.isDataLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setCurrentPage(state, payload) {
    state.serials.meta.current_page = payload.value;
  },
};

const actions = {
  fetchSerialsFilter: ({ commit }, data) => {
    commit("setIsSerialsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.fetchSerialsFilter(data)
        .then((res) => {
          commit("setIsSerialsLoaded", true);
          commit("setSerials", res.data);
          commit("setErrors", []);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setIsSerialsLoaded", true);
          let nameError = Object.values(error.response.data.errors);
          commit("setErrors", nameError[0]);
          reject(error);
        });
    });
  },
  deleteSerial: ({ commit }, id) => {
    commit("setIsSerialsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.deleteSerial(id)
        .then((response) => {
          commit("setErrors", "");
          commit("setIsSerialsLoaded", true);
          resolve(response);
        })
        .catch((error) => {
          commit("setIsSerialsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  ReviewSerials: ({ commit }, data) => {
    commit("setIsSerialsLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.ReviewSerials(data)
        .then((response) => {
          commit("setIsSerialsLoaded", true);
          resolve(response.data);
        })
        .catch((error) => {
          commit("setIsSerialsLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
