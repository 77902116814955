import { FixedAssetAPI } from "@/helpers/Apis/index";

const state = {
  fixedassets: [],
  errors: [],
  isFixedAssetsLoaded: true,

  fixedasset: {},
};

const getters = {
  isFixedAssetsLoaded(state) {
    return state.isFixedAssetsLoaded;
  },
  getFixedAssets(state) {
    return state.fixedassets;
  },
  getFixedAsset(state) {
    return state.fixedasset;
  },

  getFixedAssetErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsFixedAssetsLoaded: (state, payload) => {
    state.isFixedAssetsLoaded = payload;
  },
  setFixedAssets: (state, payload) => {
    state.fixedassets = payload;
  },
  setFixedAsset: (state, payload) => {
    state.fixedasset = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.fixedassets.meta.current_page = payload.value;
  },

  setNewUpdateFixedAsset(state, payload) {
    let fixedassetIndex = state.fixedassets.data.findIndex((o) => o.id == payload.id);
    if (fixedassetIndex !== -1) {
      // state.categories[categoryIndex] = payload;
      state.fixedassets.data.splice(fixedassetIndex, 1, { ...payload });
    } else {
      state.fixedassets.data.unshift(payload);
      state.fixedassets.total_count += 1;
    }

    let FixedAssetsLength = state.fixedassets.data.length;
    if (FixedAssetsLength > state.fixedassets.meta.per_page) {
      state.fixedassets.data.splice(FixedAssetsLength - 1, 1);
    }
  },
  deleteFixedAsset(state, payload) {
    let fixedassetIndex = state.fixedassets.data.findIndex((o) => o.id === payload);
    if (fixedassetIndex !== -1) {
      state.fixedassets.data.splice(fixedassetIndex, 1);
    }
  },
};

const actions = {
  fetchFixedAssets: ({ commit }, data) => {
    commit("setIsFixedAssetsLoaded", false);
    return new Promise((resolve, reject) => {
      FixedAssetAPI.fetchFixedAssets(data)
        .then((res) => {
          commit("setFixedAssets", res.data);
          commit("setIsFixedAssetsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsFixedAssetsLoaded", true);
          reject(error);
        });
    });
  },

  fetchFixedAsset: ({ commit }, id) => {
    commit("setIsFixedAssetsLoaded", false);
    return new Promise((resolve, reject) => {
      FixedAssetAPI.fetchFixedAsset(id)
        .then((res) => {
          commit("setFixedAsset", res.data.data);
          commit("setIsFixedAssetsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsFixedAssetsLoaded", true);
          reject(error);
        });
    });
  },

  createFixedAsset: ({ commit }, data) => {
    commit("setIsFixedAssetsLoaded", false);
    return new Promise((resolve, reject) => {
      FixedAssetAPI.createFixedAsset(data)
        .then((res) => {
          commit("setErrors", "");
          if (!data.fastCreate) {
            commit("setNewUpdateFixedAsset", res.data.data);
          }
          resolve(res.data.data);
          commit("setIsFixedAssetsLoaded", true);
        })
        .catch((error) => {
          commit("setIsFixedAssetsLoaded", true);
          let nameError = Object.values(error.response.data.errors);
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateFixedAsset: ({ commit }, data) => {
    commit("setIsFixedAssetsLoaded", false);
    return new Promise((resolve, reject) => {
      FixedAssetAPI.updateFixedAsset(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setNewUpdateFixedAsset", res.data.data);
          commit("setIsFixedAssetsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsFixedAssetsLoaded", true);
          let nameError = Object.values(error.response.data.errors);
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteFixedAsset: ({ commit }, id) => {
    commit("setIsFixedAssetsLoaded", false);
    return new Promise((resolve, reject) => {
      FixedAssetAPI.deleteFixedAsset(id)
        .then((res) => {
          commit("setIsFixedAssetsLoaded", true);
          commit("setErrors", "");
          commit("deleteFixedAsset", id);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsFixedAssetsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);

          reject(error);
        });
    });
  },

  fetchDepreciations: ({ commit }, data) => {
    commit("setIsFixedAssetsLoaded", false);
    return new Promise((resolve, reject) => {
      FixedAssetAPI.fetchDepreciations(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsFixedAssetsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsFixedAssetsLoaded", true);
          let nameError = Object.values(error.response.data.errors);
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  saveDepreciations: ({ commit }, data) => {
    commit("setIsFixedAssetsLoaded", false);
    return new Promise((resolve, reject) => {
      FixedAssetAPI.saveDepreciations(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsFixedAssetsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsFixedAssetsLoaded", true);
          let nameError = Object.values(error.response.data.errors);
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
