import * as APIs from "@/helpers/Apis/ShowsIn";
import i18n from "@/plugins/i18n/i18n";

const state = {
  showsin: [],
  errors: [],
  isShowsInLoaded: false,
};

const getters = {
  isShowsInLoaded(state) {
    return state.isShowsInLoaded;
  },
  getShowsIn(state) {
    return state.showsin;
  },
};

const mutations = {
  setIsShowsInLoaded: (state, payload) => {
    state.isShowsInLoaded = payload;
  },
  setShowsIn: (state, payload) => {
    state.showsin = payload;
  },
};

const actions = {
  fetchShowsIn: ({ commit }) => {
    commit("setIsShowsInLoaded", false);
    APIs.fetchShowsIn()
      .then((response) => {
        commit("setShowsIn", response.data);
        commit("setIsShowsInLoaded", true);
      })
      .catch((error) => {
        commit("setIsShowsInLoaded", false);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
