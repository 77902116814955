import baseUrl from "@/constants/API";

export const RestaurantOrderAPI = {
  fetchRestaurantOrder(id) {
    return axios.get(baseUrl() + `/restaurant/order/${id}`);
  },
  createRestaurantOrder(data) {
    return axios.post(baseUrl() + `/restaurant/order/create`, data);
  },

  updateRestaurantOrder(data) {
    return axios.post(baseUrl() + `/restaurant/order/update/${data.id}`, data.data);
  },
  deleteRestaurantOrder(id) {
    return axios.delete(baseUrl() + `/restaurant/order/delete/${id}`);
  },

  // restaurantOrderDetails(order_id) {
  //   return axios.post(baseUrl() + `/restaurant/order/details/${order_id}`);
  // },

  fetchDeletedRestaurantOrders(data) {
    return axios.post(baseUrl() + `/restaurant/order/deleted?page=${data.pageNumber}`, data.data);
  },
  // getDeletedRestaurantOrderDetails(invoice_id) {
  //   return axios.post(baseUrl() + `/restaurant/order/deleted/${invoice_id}`);
  // },
  clearDeletedRestaurantOrders(data) {
    return axios.delete(baseUrl() + `/restaurant/order/deleted/clear`, data);
  },
};
