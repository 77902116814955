import baseUrl from "@/constants/API";

const fetchSerials = (data) => {
  return axios.get(baseUrl() + `/itemserials/serials?page=${data}`);
};

const deleteSerial = (id) => {
  return axios.delete(baseUrl() + `/itemserials/delete/${id}`);
};
const deleteTransaction = (id) => {
  return axios.delete(baseUrl() + `/itemserials/delete/transaction/${id}`);
};

const fetchItemSerialsChecker = (data) => {
  return axios.post(baseUrl() + `/itemserials/itemchecker`, data);
};

const fetchSerialsFilter = (data) => {
  return axios.post(baseUrl() + `/itemserials/serialsfilter?page=${data.pageNumber}`, data.data);
};

const fetchTransactionsFilter = (data) => {
  return axios.post(
    baseUrl() + `/itemserials/transactionsfilter?page=${data.pageNumber}`,
    data.data
  );
};

const ReviewSerials = (data) => {
  return axios.get(baseUrl() + `/itemserials/reviewserials`, data);
};

// Serials Fetch  Serials Fetch  Serials Fetch
const fetchSaleReturnSerials = (data) => {
  return axios.post(baseUrl() + `/itemserials/sreturnserials`, data);
};
const fetchPurchaseReturnSerials = (data) => {
  return axios.post(baseUrl() + `/itemserials/preturnserials`, data);
};
const fetchSerialsRelated = (data) => {
  return axios.post(baseUrl() + `/itemserials/serialsrelated`, data);
};

const fetchSaleSerials = (data) => {
  return axios.post(baseUrl() + `/itemserials/saleserials`, data);
};
const fetchTransferSerials = (data) => {
  return axios.post(baseUrl() + `/itemserials/transferserials`, data);
};

export {
  fetchSerials,
  fetchItemSerialsChecker,
  fetchSerialsFilter,
  fetchTransactionsFilter,
  deleteSerial,
  deleteTransaction,
  ReviewSerials,

  // Serials Fetch  Serials Fetch  Serials Fetch
  fetchSaleSerials,
  fetchSaleReturnSerials,
  fetchPurchaseReturnSerials,
  fetchSerialsRelated,
  fetchTransferSerials,
};
