import baseUrl from "@/constants/API";

const getProportionProduction = (id) => {
  return axios.get(baseUrl() + `/production/proportion/${id}`);
};
const getProportionProductions = (data) => {
  // return axios.get(baseUrl() + `/production/all?page=${pageNumber}`);
  let page = data?.page ? data.page : data;
  let invoice_id = data?.invoice_id ? data.invoice_id : null;
  return axios.get(baseUrl() + `/production/proportion/${invoice_id}?page=${page}`);
};

const createProportionProduction = (data) => {
  return axios.post(baseUrl() + `/production/proportion/create`, data);
};
const updateProportionProduction = (data) => {
  return axios.post(baseUrl() + `/production/proportion/update/${data.id}`, data.data);
};
const createProductDetails = (data) => {
  return axios.post(baseUrl() + `/production/proportion/detail/create`, data);
};

const deleteProduction = (id) => {
  return axios.delete(baseUrl() + `/production/delete/${id}`);
};

const deleteProductionDetail = (id) => {
  return axios.delete(baseUrl() + `/production/proportion/detail/delete/${id}`);
};

// deleted STUFF
// const fetchDeletedInvoices = (data) => {
//   return axios.post(baseUrl() + `/production/deleted?page=${data.pageNumber}`, data.data);
// };
// const fetchDeletedInvoiceDetails = (invoice_id) => {
//   return axios.post(baseUrl() + `/production/deleted/${invoice_id}`);
// };
// const clearDeletedInvoices = (data) => {
//   return axios.delete(baseUrl() + `/production/deleted/clear`, data);
// };

export {
  getProportionProduction,
  getProportionProductions,
  createProportionProduction,
  updateProportionProduction,
  createProductDetails,
  deleteProduction,
  deleteProductionDetail,
  // deleted STUFF
  // fetchDeletedInvoices,
  // fetchDeletedInvoiceDetails,
  // clearDeletedInvoices,
};
