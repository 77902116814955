import auth from "@/router/middleware/auth";

function lazyLoad(folderName, view) {
  return () => import(/* webpackChunkName: "production" */ `@containers/${folderName}/${view}.vue`);
}

const InvoicesRoutes = [
  {
    path: "/freeproductions",
    name: "ProductionsFree",
    component: lazyLoad("Productions", "FreeProductions"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/freeproductions/:id(\\d+)",
    name: "SingleFreeProduction",
    component: lazyLoad("Productions", "FreeProduction"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/freeproductions/create",
    name: "CreateFreeProduction",
    component: lazyLoad("Productions", "FreeProduction"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/productionproportions",
    name: "ProductionProportions",
    component: lazyLoad("Productions/ProductionProportions", "ProductionProportions"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/proportionproductions",
    name: "ProportionProductions",
    component: lazyLoad("Productions", "ProportionProductions"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/proportionproductions/:id(\\d+)",
    name: "SingleProportionProduction",
    component: lazyLoad("Productions", "ProportionProduction"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/proportionproductions/create",
    name: "CreateProportionProduction",
    component: lazyLoad("Productions", "ProportionProduction"),
    meta: {
      middleware: [auth],
    },
  },
];

export default InvoicesRoutes;
