import baseUrl from "@/constants/API";

export const ReservationAPI = {
  fetchReservation(id) {
    return axios.get(baseUrl() + `/reservation/${id}`);
  },
  createReservation(data) {
    return axios.post(baseUrl() + `/reservation/create`, data);
  },
  fetchReservations(data) {
    // return axios.get(baseUrl() + `/Reservation/all?page=${pageNumber}`);
    let page = data?.page ? data.page : data;
    let invoice_id = data?.invoice_id ? data.invoice_id : null;
    return axios.get(baseUrl() + `/reservation/invoices/${invoice_id}?page=${page}`);
  },
  updateReservation(data) {
    return axios.post(baseUrl() + `/reservation/update/${data.id}`, data.data);
  },
  deleteReservation(id) {
    return axios.delete(baseUrl() + `/reservation/delete/${id}`);
  },

  fetchReservationDetails(invoice_id) {
    return axios.post(baseUrl() + `/reservation/details/${invoice_id}`);
  },

  convertToSale(data) {
    return axios.post(baseUrl() + `/reservation/converttosale/${data.reservationId}`, data.data);
  },

  // deleted STUFF
  fetchDeletedInvoices(data) {
    return axios.post(baseUrl() + `/reservation/deleted?page=${data.pageNumber}`, data.data);
  },
  fetchDeletedInvoiceDetails(invoice_id) {
    return axios.post(baseUrl() + `/reservation/deleted/${invoice_id}`);
  },
  clearDeletedInvoices(data) {
    return axios.delete(baseUrl() + `/reservation/deleted/clear`, data);
  },
};
