import Vue from "vue";
import { mapGetters } from "vuex";
import {
  InvoiceTypeIds,
  InvoiceTypes,
  EntryTypes,
  partTypes,
  PriceTypes,
  BalanceTypes,
} from "@/helpers/Constants/General";
import { CompanyPermissions } from "@/helpers/Permissions/CompanyPermissions";
import Permissions from "@/helpers/Permissions/Permissions.js";
import { writeFile, utils } from "xlsx";

const globalMixins = {
  computed: {
    ...mapGetters("User", ["getUser", "getUserAccounts", "isUserLoaded", "getBeginDate"]),
    ...mapGetters("Company", ["getCompanyData"]),
    ...mapGetters("App", ["getLanguage"]),
    databaseSettings() {
      return this.getUser.database_settings;
    },
    getDarkMode() {
      return this.$vuetify.theme.dark;
    },

    priceTypes() {
      return PriceTypes;
    },
    balanceTypes() {
      return BalanceTypes;
    },
    partTypes() {
      return partTypes;
    },
    InvoiceTypeIds() {
      return InvoiceTypeIds;
    },
    InvoiceTypes() {
      return InvoiceTypes;
    },
    EntryTypes() {
      return EntryTypes;
    },
    CompanyPermissions() {
      return CompanyPermissions;
    },
    // isAuthenticated() {
    //   return store.getters["User/getAuthentication"];
    // },

    isAdmin() {
      return this.getUser?.role?.id == 1 ? true : false;
    },

    allowChangeDate() {
      return this.permissionChecker("admin")
        ? true
        : !this.permissionChecker("general-disallowchangingtodaydate");
    },
    maxAllowedDate() {
      if (this.permissionChecker("admin")) {
        return null;
      }

      return this.permissionChecker("general-disallowmovementaftertoday") ? this.today() : null;
    },
  },
  methods: {
    toFixedIfNecessary(value, dp) {
      return +parseFloat(value).toFixed(dp);
    },
    // formatNumberWithCommas(number) {
    //   // if (Math.abs(number) < 1000) {
    //   //   return number;
    //   // }
    //   // return new Intl.NumberFormat("en-US").format(number);

    //   return new Intl.NumberFormat("en-US").format(number);
    // },

    permissionChecker(value) {
      // checks if the permission exists in the permissions file
      if (!Permissions.includes(value)) {
        return true;
      }

      // checks if permission exists in the user
      let permissions = this.getUser?.permissions;
      if (permissions?.includes(value) || permissions?.includes("admin")) {
        return true;
      } else {
        return false;
      }
    },

    companyPermissionChecker(value) {
      if (this.getCompanyData.permissions?.includes(value)) {
        return true;
      } else {
        return false;
      }
    },
    // roleChecker(value) {
    //   let role = this.getUser?.role?.name;
    //   if (role == value) {
    //     // if (role == value || role == "Admin") {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      // return `${day}-${month}-${year}`;
      return `${year}-${month}-${day}`;
    },
    // isValidDate(value) {
    //   // First check if the value is a valid string or date object
    //   if (Object.prototype.toString.call(value) === "[object Date]" || typeof value === "string") {
    //     const date = new Date(value);
    //     // Ensure that the date object is valid
    //     return !isNaN(date.getTime());
    //   }
    //   return false;
    // },

    customerDName(value) {
      switch (value) {
        case 1:
          return this.$t("client");
        case 2:
          return this.$t("supplier");
        case 3:
          return this.$t("employee");
        // case 4:
        //   return this.$t("owner");
        case 5:
          return this.$t("maintenanceclient");
        case 6:
          return this.$t("cleanningclients");
        default:
          return this.$t("part");
      }
    },

    // toFindDuplicates(arry) {
    //   // to get duplicated objects in an array
    //   const uniqueElements = new Set(arry);
    //   const filteredElements = arry.filter((item) => {
    //     if (uniqueElements.has(item)) {
    //       uniqueElements.delete(item);
    //     } else {
    //       return item;
    //     }
    //   });

    //   return [...new Set(uniqueElements)];
    // },

    focus(El) {
      if (this.$refs[El]) {
        this.$refs[El].$el.querySelector("input").select();
      }
    },

    serialsMap(detail) {
      let serials = detail.serials || detail.newSerials;
      if (serials) {
        return serials
          .map(function (elem) {
            return elem.serial;
          })
          .join(" , ");
      }
      return "";
    },

    basicExportExcel(
      tableHeaders,
      data,
      headersToSum = [],
      keyValuesToExclude = ["Number", "Actions"]
    ) {
      if (!data || data.length <= 0) {
        return iziToast.error({
          message: this.$t("nodata"),
          type: "error",
        });
      }

      const filteredHeaders = tableHeaders
        .filter(({ keyValue }) => !keyValuesToExclude.includes(keyValue))
        .map(({ text, value, keyValue }) => ({ text, value, keyValue }));
      const headers = filteredHeaders.map((header) => header.text);

      const getValue = (item, path, keyValue) => {
        if (keyValue === "Serials") {
          return this.serialsMap(item);
        } else if (keyValue === "Expire") {
          let expiration = item.expiration;
          if (!expiration) {
            return "";
          }
          let batchNumber = expiration?.batch_number ? ` ( ${expiration?.batch_number} )` : "";
          return `${expiration?.expire_date}` + `${batchNumber}`;
        } else {
          return path.split(".").reduce((acc, part) => acc && acc[part], item) || "";
        }
      };
      const excelData = data.map((item) => {
        return filteredHeaders.reduce((obj, { text, value, keyValue }) => {
          obj[text] = getValue(item, value, keyValue);
          return obj;
        }, {});
      });

      this.toExcel(headers, excelData, headersToSum, filteredHeaders);
    },

    toExcel(headers, data, headersToSum = [], filteredHeaders) {
      // Create the workbook and worksheet
      const workbook = utils.book_new();

      // Find indexes of headers to sum, to map them to Excel columns
      const sumKeyValues = headersToSum.map((headerKeyValue) =>
        filteredHeaders.find(({ keyValue }) => keyValue === headerKeyValue)
      );
      const sumHeaderIndexes = sumKeyValues
        .map((header) => (header ? headers.indexOf(header.text) : -1))
        .filter((index) => index !== -1);

      // Adjust data to include SUM formulas at the bottom
      const excelDataWithSums = [...data]; // Clone data to manipulate
      const sumRow = {};
      // to add Automatically calculations
      // sumHeaderIndexes.forEach((index) => {
      //   // Excel columns start at 1 for headers, so data rows start at 2
      //   const columnLetter = String.fromCharCode(65 + index); // Convert to Excel column letter
      //   const formula = `=SUM(${columnLetter}2:${columnLetter}${data.length + 1})`; // +1 because headers count as a row
      //   sumRow[headers[index]] = formula; // Insert formula under the correct header
      // });
      // to add Static calculations
      sumHeaderIndexes.forEach((index) => {
        const sum = data.reduce((acc, row) => acc + Number(row[headers[index]] || 0), 0);
        sumRow[headers[index]] = sum; // Insert sum as a direct value
      });
      // Add the sumRow to the bottom of your data
      excelDataWithSums.push(sumRow);

      const worksheet = utils.json_to_sheet(excelDataWithSums, { header: headers });
      utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Write the workbook and trigger download
      writeFile(workbook, "a5Excel.xls"); //xlsx  xls  csv
    },
  },
};

Vue.mixin(globalMixins);
