import * as BeginningStoreAPIs from "@/helpers/Apis/Beginning/BeginningStoreDetails";
import i18n from "@/plugins/i18n/i18n";

const state = {
  beginningstoredetails: [],
  beginningbalancedetails: [],

  isBeginningStoreLoaded: true,
  errors: [],
};

const getters = {
  getBeginningStoreDetails(state) {
    return state.beginningstoredetails;
  },
  getBeginningBalanceDetails(state) {
    return state.beginningbalancedetails;
  },

  isBeginningStoreLoaded(state) {
    return state.isBeginningStoreLoaded;
  },
  getBeginningDetailErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsBeginningStoreLoaded: (state, payload) => {
    state.isBeginningStoreLoaded = payload;
  },
  setBeginningStoreDetails: (state, payload) => {
    state.beginningstoredetails = payload;
  },

  setBeginningBalanceDetails: (state, payload) => {
    state.beginningbalancedetails = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setCurrentPage(state, payload) {
    state.beginningstoredetails.meta.current_page = payload.value;
  },
  updateDetails(state, payload) {
    let detailData = state.beginningstoredetails.data;
    let data = payload.data;
    let index = payload.index;
    if (index == null) {
      detailData.push(data);
    } else {
      detailData.splice(index, 1, { ...data });
      // detailData[index].item_id = data.item.id;
      // detailData[index].item_name = data.item.name;
      // detailData[index].barcode = data.item.barcode;
      // detailData[index].item = data.item;
      // detailData[index].howmanyinbig = data.item.howmanyinbig || 0;

      // detailData[index].unit = data.unit || 0;
      // detailData[index].piece = data.piece || 0;
      // detailData[index].equivalent = data.equivalent || 0;

      // detailData[index].discount_value = data.discount_value || 0;
      // detailData[index].discount_ratio = data.discount_ratio || 0;
      // detailData[index].tax_value = data.tax_value || 0;
      // detailData[index].tax_ratio = data.tax_ratio || 0;

      // detailData[index].price = data.price || 0;

      // detailData[index].total = data.total || 0;
      // detailData[index].total_before = data.total_before || 0;

      // detailData[index].newSerials = data.newSerials;
      // detailData[index].serials = data.serials;
      // detailData[index].expire_date = data.expire_date;
    }
  },
};

const actions = {
  fetchBeginningStoreDetails: ({ commit }, data) => {
    commit("setIsBeginningStoreLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      BeginningStoreAPIs.fetchBeginningStoreDetails(data)
        .then((res) => {
          commit("setBeginningStoreDetails", res.data);
          commit("setIsBeginningStoreLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsBeginningStoreLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          reject(error);
        });
    });
  },

  fetchBeginningDetails: ({ commit }) => {
    commit("setIsBeginningStoreLoaded", false);
    BeginningStoreAPIs.fetchBeginningDetails()
      .then((res) => {
        commit("setBeginningStoreDetails", res.data);
        commit("setIsBeginningStoreLoaded", true);
      })
      .catch((error) => {
        commit("setIsBeginningStoreLoaded", true);
      });
  },

  createBeginningDetail: ({ commit }, data) => {
    commit("setIsBeginningStoreLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      BeginningStoreAPIs.createBeginningDetail(data)
        .then((res) => {
          commit("setErrors", "");
          commit("setIsBeginningStoreLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          commit("updateDetails", { data: res.data.data, index: data.index });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsBeginningStoreLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateBeginningDetail: ({ commit }, data) => {
    commit("setIsBeginningStoreLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      BeginningStoreAPIs.updateBeginningDetail(data)
        .then((res) => {
          commit("setErrors", "");
          commit("App/setAppLoader", false, { root: true });
          commit("setIsBeginningStoreLoaded", true);
          commit("updateDetails", { data: res.data.data, index: data.index });
          resolve(res.data);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setIsBeginningStoreLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  deleteBeginningDetail: ({ commit }, id) => {
    commit("setIsBeginningStoreLoaded", false);
    return new Promise((resolve, reject) => {
      BeginningStoreAPIs.deleteBeginningDetail(id)
        .then((res) => {
          commit("setIsBeginningStoreLoaded", true);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsBeginningStoreLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
