import baseUrl from "@/constants/API";

export const PurchaseReturnAPI = {
  fetchPurchaseReturns(id) {
    return axios.get(baseUrl() + `/purchasereturn/${id}`);
  },
  createPurchaseReturn(data) {
    return axios.post(baseUrl() + `/purchasereturn/create`, data);
  },
  updatePurchaseReturn(data) {
    return axios.post(baseUrl() + `/purchasereturn/update/${data.id}`, data.data);
  },
  deletePurchaseReturn(id) {
    return axios.delete(baseUrl() + `/purchasereturn/delete/${id}`);
  },

  fetchPurchasesReturns(data) {
    // return axios.get(baseUrl() + `/purchasereturn/all?page=${pageNumber}`);
    let page = data?.page ? data.page : data;
    let invoice_id = data?.invoice_id ? data.invoice_id : null;
    return axios.get(baseUrl() + `/purchasereturn/invoices/${invoice_id}?page=${page}`);
  },

  fetchPurchaseReturnRelated(id) {
    return axios.get(baseUrl() + `/purchasereturn/singlerelated/${id}`);
  },
  createPurchaseRelated(data) {
    return axios.post(baseUrl() + "/purchasereturn/createrelated", data);
  },
  updatePurchaseRelated(data) {
    return axios.post(baseUrl() + `/purchasereturn/updaterelated/${data.id}`, data.data);
  },

  purchaseReturnDetails(invoice_id) {
    return axios.post(baseUrl() + `/purchasereturn/details/${invoice_id}`);
  },

  deleteReturnRelated(invoice_id) {
    return axios.delete(baseUrl() + `/purchasereturn/relateddelete/${invoice_id}`);
  },

  // deleted STUFF
  fetchDeletedInvoices(data) {
    return axios.post(baseUrl() + `/purchasereturn/deleted?page=${data.pageNumber}`, data.data);
  },
  fetchDeletedInvoiceDetails(invoice_id) {
    return axios.post(baseUrl() + `/purchasereturn/deleted/${invoice_id}`);
  },
  clearDeletedInvoices(data) {
    return axios.delete(baseUrl() + `/purchasereturn/deleted/clear`, data);
  },
};
