import { EmployeeJobAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  types: [],
  errors: [],
  isJobsLoaded: false,
};

const getters = {
  isEmployeeJobsLoaded(state) {
    return state.isJobsLoaded;
  },
  getEmployeeJobs(state) {
    return state.types;
  },
  getEmployeeJobsErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsEmployeeJobsLoaded: (state, payload) => {
    state.isJobsLoaded = payload;
  },
  setEmployeeJobs: (state, payload) => {
    state.types = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  // EmployeeJob: ({ commit }, id) => {
  //   commit("setIsEmployeeJobFetched", false);
  //   EmployeeJobAPI.getEmployeeJob(id)
  //     .then((response) => {
  //       commit("setEmployeeJob", response.data);
  //       commit("setIsEmployeeJobFetched", true);
  //     })
  //     .catch((error) => {
  //       commit("setIsEmployeeJobFetched", true);
  //     });
  // },

  fetchEmployeeJobs: ({ commit }) => {
    commit("setIsEmployeeJobsLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeJobAPI.fetchEmployeeJobs()
        .then((res) => {
          commit("setEmployeeJobs", res.data);
          commit("setIsEmployeeJobsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEmployeeJobsLoaded", true);
          reject(error);
        });
    });
  },

  createEmployeeJob: ({ commit, dispatch }, data) => {
    commit("setIsEmployeeJobsLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeJobAPI.createEmployeeJob(data)
        .then((res) => {
          if (res.status == 200) {
            commit("setErrors", "");
            dispatch("fetchEmployeeJobs");
          }
          commit("setIsEmployeeJobsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEmployeeJobsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateEmployeeJob: ({ commit, dispatch }, data) => {
    commit("setIsEmployeeJobsLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeJobAPI.updateEmployeeJob(data)
        .then((res) => {
          if (res.status == 200) {
            commit("setErrors", "");
            dispatch("fetchEmployeeJobs");
          }
          commit("setIsEmployeeJobsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEmployeeJobsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  deleteEmployeeJob: ({ state, commit, dispatch }, data) => {
    commit("setIsEmployeeJobsLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeJobAPI.deleteEmployeeJob(data)
        .then((res) => {
          if (res.status == 200) {
            commit("setErrors", "");
            dispatch("fetchEmployeeJobs");
          }
          commit("setIsEmployeeJobsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEmployeeJobsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  searchEmployeeJob: ({ commit }, data) => {
    commit("setIsEmployeeJobsLoaded", false);
    return new Promise((resolve, reject) => {
      EmployeeJobAPI.searchEmployeeJob(data)
        .then((res) => {
          commit("setEmployeeJobs", res.data);
          commit("setIsEmployeeJobsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsEmployeeJobsLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
