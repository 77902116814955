import baseUrl from "@/constants/API";

const fetchExpirations = () => {
  return axios.get(baseUrl() + `/itemexpire/all`);
};
const ReviewExpireDates = () => {
  return axios.get(baseUrl() + `/itemexpire/reviewexpiredates`);
};

const fetchExpiresTransactionsFiltered = (data) => {
  return axios.post(
    baseUrl() + `/itemexpire/expiretransactionsfilter?page=${data.pageNumber}`,
    data.data
  );
  // return axios.post(baseUrl() + "/itemexpire/expiretransactionsfilter", data);
};
const fetchExpiresQtyFiltered = (data) => {
  return axios.post(baseUrl() + `/itemexpire/expireqtyfilter?page=${data.pageNumber}`, data.data);
  // return axios.post(baseUrl() + "/itemexpire/expireqtyfilter", data);
};

// Expire Fetches Expire Fetches Expire Fetches Expire Fetches
const fetchAvailableExpires = (data) => {
  return axios.post(baseUrl() + `/itemexpire/availableexpires`, data);
};

const fetchAvailableExpiresRelated = (data) => {
  return axios.post(baseUrl() + "/itemexpire/availableexpiresrelated", data);
};

export {
  fetchExpirations,
  ReviewExpireDates,
  fetchExpiresTransactionsFiltered,
  fetchExpiresQtyFiltered,

  // Expire Fetches  Expire Fetches  Expire Fetches
  fetchAvailableExpires,
  fetchAvailableExpiresRelated,
};
