const Permissions = [
  "admin",
  "general-viewprofitandcost",
  "general-disallowchangingtodaydate",
  "general-disallowmovementaftertoday",

  "general-clientsreceivable",
  "general-supplierspayable",
  "general-ledger",
  "general-finalbalancesheet",

  "beginningstore-view",
  "beginningstore-create",
  "beginningstore-update",
  "beginningstore-delete",

  "beginningbalance-view",
  "beginningbalance-create",
  "beginningbalance-update",
  "beginningbalance-delete",

  "fixedassets-view",
  "fixedassets-create",
  "fixedassets-update",
  "fixedassets-delete",
  "fixedassets-calculate_depreciation_ratio",

  "client-view",
  "client-create",
  "client-update",
  "client-delete",

  "supplier-view",
  "supplier-create",
  "supplier-update",
  "supplier-delete",

  "mainacc-view",
  "mainacc-create",
  "mainacc-update",
  "mainacc-delete",

  "subacc-view",
  "subacc-create",
  "subacc-update",
  "subacc-delete",

  "branch-view",
  "branch-create",
  "branch-update",
  "branch-delete",

  "drawer-view",
  "drawer-create",
  "drawer-update",
  "drawer-delete",

  "employee-view",
  "employee-create",
  "employee-update",
  "employee-delete",
  "employee-givesalary",
  "employee-giveloan",
  "employee-calccommission",
  "employee-paycommission",
  "employeejob-create",
  "employeejob-update",
  "employeejob-delete",

  "store-view",
  "store-create",
  "store-update",
  "store-delete",
  "store-viewqty",
  "store-searchdetail",
  "inventorycount-view",

  "category-view",
  "category-create",
  "category-update",
  "category-delete",

  "item-view",
  "item-create",
  "item-update",
  "item-delete",

  "entry-personaldata",
  "entry-view",
  "entry-create",
  "entry-update",
  "entry-delete",
  "entry-searchdetail",

  "draweraction-withdraw",
  "draweraction-deposit",
  "draweraction-cashtransfer",

  "sale-personaldata",
  "sale-view",
  "sale-create",
  "sale-update",
  "sale-delete",
  "sale-canprice",
  "sale-candiscount",
  "sale-candiscount2",
  "sale-candiscount3",
  "sale-cantax",
  "sale-paytype",
  "sale-selllesshalfcommercial",
  "sale-selllesscommercial",
  "sale-selllesshalfwhole",
  "sale-selllesswhole",
  "sale-selllessconsumer",
  "sale-selllessaverage",
  "sale-selllesspurchase",
  "sale-deletedpreview",
  "sale-itemsales",
  "sale-saleagentscollection",
  "sale-saleagentsclients",
  "sale-saleagentsitems",
  "sale-salesagentcommission",

  "salerelated-create",
  "salerelated-update",
  "salerelated-delete",
  "salerelated-canprice",
  "salerelated-candiscount",
  "salerelated-candiscount2",
  "salerelated-candiscount3",
  "salerelated-cantax",

  "reservation-personaldata",
  "reservation-view",
  "reservation-create",
  "reservation-update",
  "reservation-delete",
  "reservation-canprice",
  "reservation-candiscount",
  "reservation-candiscount2",
  "reservation-candiscount3",
  "reservation-cantax",

  "saleorder-personaldata",
  "saleorder-view",
  "saleorder-create",
  "saleorder-update",
  "saleorder-delete",
  "saleorder-canprice",
  "saleorder-candiscount",
  "saleorder-candiscount2",
  "saleorder-candiscount3",
  "saleorder-cantax",

  "cashier-view",
  "cashier-create",
  "cashier-update",
  "cashier-delete",
  "cashier-candiscount",
  "cashier-cantax",
  "cashier-deletedpreview",

  "purchase-personaldata",
  "purchase-view",
  "purchase-create",
  "purchase-update",
  "purchase-delete",
  "purchase-deletedpreview",
  "purchase-itempurchases",

  "purchaserelated-create",
  "purchaserelated-update",
  "purchaserelated-delete",

  "purchaseorder-personaldata",
  "purchaseorder-view",
  "purchaseorder-create",
  "purchaseorder-update",
  "purchaseorder-delete",

  "salereturn-personaldata",
  "salereturn-view",
  "salereturn-create",
  "salereturn-update",
  "salereturn-delete",
  "salereturn-canprice",
  "salereturn-candiscount",
  "salereturn-candiscount2",
  "salereturn-candiscount3",
  "salereturn-cantax",
  "salereturn-paytype",
  "salereturn-deletedpreview",
  "salereturn-itemreturns",

  "purchasereturn-personaldata",
  "purchasereturn-view",
  "purchasereturn-create",
  "purchasereturn-update",
  "purchasereturn-delete",
  "purchasereturn-deletedpreview",
  "purchasereturn-itemreturns",

  "storein-personaldata",
  "storein-view",
  "storein-create",
  "storein-update",
  "storein-delete",
  "storein-canprice",

  "storeout-personaldata",
  "storeout-view",
  "storeout-create",
  "storeout-update",
  "storeout-delete",
  "storeout-canprice",

  "transfer-personaldata",
  "transfer-view",
  "transfer-create",
  "transfer-update",
  "transfer-delete",
  "transfer-canprice",
  "transfer-candelivered",
  "transfer-delivered",

  "payable-personaldata",
  "payable-view",
  "payable-create",
  "payable-update",
  "payable-delete",

  "receivable-personaldata",
  "receivable-view",
  "receivable-create",
  "receivable-update",
  "receivable-delete",

  "production-personaldata",
  "production-view",
  "production-create",
  "production-update",
  "production-delete",

  "maintenance-personaldata",
  "maintenance-view",
  "maintenance-create",
  "maintenance-update",
  "maintenance-delete",

  "cleanning-personaldata",
  "cleanning-view",
  "cleanning-create",
  "cleanning-update",
  "cleanning-delete",
];
export default Permissions;
