import { ItemAPI } from "@/helpers/Apis/Item/Items";
import i18n from "@/plugins/i18n/i18n";

const state = {
  items: [],
  isItemsLoaded: true,
};

const getters = {
  isItemsLoaded(state) {
    return state.isItemsLoaded;
  },
  getItems(state) {
    return state.items;
  },
};

const mutations = {
  setIsItemsLoaded: (state, payload) => {
    state.isItemsLoaded = payload;
  },
  setItems: (state, payload) => {
    state.items = payload;
  },
};

const actions = {
  fetchAllItems: ({ commit }) => {
    commit("setIsItemsLoaded", false);
    return new Promise((resolve, reject) => {
      ItemAPI.fetchAllItems()
        .then((res) => {
          commit("setItems", res.data);
          commit("setIsItemsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsItemsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
