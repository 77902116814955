import * as APIs from "@/helpers/Apis/CleanningInvoice/CleanningClients";
import i18n from "@/plugins/i18n/i18n";

const state = {
  data: [],
  errors: [],
  isReportLoaded: true,

  currentPage: null,
};

const getters = {
  isReportLoaded(state) {
    return state.isReportLoaded;
  },
  getReportData(state) {
    return state.data;
  },
  getErrors(state) {
    return state.errors;
  },

  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setIsReportLoaded: (state, payload) => {
    state.isReportLoaded = payload;
  },
  setData: (state, payload) => {
    state.data = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setCurrentPage(state, payload) {
    state.data.meta.current_page = payload.value;
  },
};

const actions = {
  CleanningClientsReceivable: ({ commit }, data) => {
    commit("setIsReportLoaded", false);
    return new Promise((resolve, reject) => {
      APIs.getCleanningClientsReceivable(data)
        .then((res) => {
          commit("setData", res.data);
          commit("setIsReportLoaded", true);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsReportLoaded", true);
          let nameError = Object.values(error.response.data.errors);
          commit("setErrors", nameError[0]);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
