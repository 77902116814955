import { MaintenanceDetailAPI } from "@/helpers/Apis/Maintenance/MaintenanceDetails";
import i18n from "@/plugins/i18n/i18n";

const state = {
  errors: [],
  isMaintenanceDetailsLoaded: true,
};

const getters = {
  isMaintenanceDetailsLoaded(state) {
    return state.isMaintenanceDetailsLoaded;
  },
  getMaintenanceDetailErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsMaintenanceDetailsLoaded: (state, payload) => {
    state.isMaintenanceDetailsLoaded = payload;
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },
};

const actions = {
  createMaintenanceItem: ({ commit }, data) => {
    commit("setIsMaintenanceDetailsLoaded", false);
    return new Promise((resolve, reject) => {
      MaintenanceDetailAPI.createMaintenanceItem(data)
        .then((res) => {
          commit("setIsMaintenanceDetailsLoaded", true);
          commit("Maintenances/addMaintenanceItem", res.data.data, { root: true });
          commit("Maintenances/calcMaintenanceItems", res.data, { root: true });
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMaintenanceDetailsLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  updateMaintenanceItem: ({ commit }, data) => {
    commit("setIsMaintenanceDetailsLoaded", false);
    return new Promise((resolve, reject) => {
      MaintenanceDetailAPI.updateMaintenanceItem(data)
        .then((res) => {
          commit("setIsMaintenanceDetailsLoaded", true);
          commit("Maintenances/updateMaintenanceItem", res.data.data, { root: true });
          commit("Maintenances/calcMaintenanceItems", res.data, { root: true });

          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMaintenanceDetailsLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },

  deleteMaintenanceDetail: ({ commit }, id) => {
    commit("setIsMaintenanceDetailsLoaded", false);
    return new Promise((resolve, reject) => {
      MaintenanceDetailAPI.deleteMaintenanceDetail(id)
        .then((res) => {
          commit("setIsMaintenanceDetailsLoaded", true);
          commit("Maintenances/deleteMaintenanceItem", id, { root: true });
          commit("Maintenances/calcMaintenanceItems", res.data, { root: true });
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMaintenanceDetailsLoaded", true);
          reject(error);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
