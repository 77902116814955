import baseUrl from "@/constants/API";

export const ClientAPI = {
  fetchClient(data) {
    return axios.get(baseUrl() + `/clients/${data.id}`);
  },
  fetchClients(data) {
    return axios.post(baseUrl() + `/clients/get?page=${data.pageNumber}`, data.data);
  },

  createClient(data) {
    return axios.post(baseUrl() + `/clients/create`, data);
  },
  updateClient(data) {
    return axios.post(baseUrl() + `/clients/update/${data.id}`, data.data);
  },
  deleteClient(data) {
    return axios.delete(baseUrl() + `/clients/delete/${data}`);
  },
  searchClients(data) {
    return axios.get(baseUrl() + `/clients/search/${data.name}`);
  },

  getClientsReceivable(data) {
    return axios.post(baseUrl() + `/clients/clientsreceivable?page=${data.pageNumber}`, data.data);
  },

  getClientBalance(id) {
    return axios.get(baseUrl() + `/clients/balance/${id}`);
  },
};
