<template>
  <v-card class="mx-auto" style="min-height: unset !important" outlined>
    <v-card-title v-if="name" class="headerText white--text justify-center">
      {{ name }}
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text class="text-center" style="font-size: 0.9rem; padding: 8px">
      <!-- You can bind total's number here -->
      <v-text-field
        v-if="isTextInput && !readOnly"
        class="centered-input"
        v-on="$listeners"
        v-bind="{ ...$attrs, ...$props }"
        dense
        single-line
        @input="handleInput"
        hide-details="auto"
        @focus="$event.target.select()"
      >
        <template v-if="$slots.prepend" v-slot:prepend>
          <slot name="prepend"></slot>
        </template>
      </v-text-field>

      <h4 v-else v-on="$listeners" v-bind="{ ...$attrs, ...$props }">
        {{ value }}
      </h4>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["value", "name", "isTextInput", "readOnly"],
  methods: {
    handleInput(e) {
      this.$emit("input", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.headerText {
  background-color: var(--v-primary-base);
  padding: 5px;
  font-size: 1rem;
}
.centered-input
  ::v-deep
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  input {
  text-align: center;
}
</style>
